import Api from '../utils/api';
import { IWaypoint } from '../interfaces/waypoint.interface';

export const WaypointService = {
  searchWaypoints: async function ({
    pageParam = 0,
  }): Promise<{ waypoints: IWaypoint[]; offset: number }> {
    if (!pageParam) {
      pageParam = 0;
    }

    const url = `/waypoint/search?offset=${pageParam}`;

    const { data } = await Api.get<IWaypoint[]>(url);

    return { waypoints: data, offset: pageParam };
  },
  getJoined: async function ({
    pageParam = 0,
  }): Promise<{ waypoints: IWaypoint[]; offset: number }> {
    if (!pageParam) {
      pageParam = 0;
    }

    const url = `/waypoint/joined?offset=${pageParam}`;

    const { data } = await Api.get<IWaypoint[]>(url);

    return { waypoints: data, offset: pageParam };
  },
  join: async function (waypointId: string): Promise<void> {
    await Api.post(`/waypoint/${waypointId}/join`);
  },
  leave: async function (waypointId: string): Promise<void> {
    await Api.post(`/waypoint/${waypointId}/leave`);
  },
};
