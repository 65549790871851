import React, { useState } from 'react';
import Layout from '../components/Layout';
import { useQuery } from 'react-query';
import ErrorToast from '../components/ErrorToast';
import { useTranslation } from 'react-i18next';
import Loader from '../components/Loader';
import { FriendshipService } from '../services/FriendshipService';
import { IonIcon } from '@ionic/react';
import {
  ellipsisHorizontal,
  flagOutline,
  stopCircleOutline,
  trashOutline,
} from 'ionicons/icons';
import { IFriendship } from '../interfaces/friendship.interface';
import ReportUserModal from '../components/ReportUserModal';
import BlockUserModal from '../components/BlockUserModal';
import RemoveFriendRequestModal from '../components/RemoveFriendRequestModal';
import Avatar from '../components/Avatar';

const RequestedFriendRequests = () => {
  const { t } = useTranslation();
  const [friendship, setFriendship] = useState<IFriendship>();

  const getRequestedFriendRequestsQuery = useQuery({
    queryKey: ['requestedFriendRequests'],
    queryFn: async () => {
      return await FriendshipService.getRequestedFriendRequests();
    },
    onError: async () => {
      ErrorToast(t('Errors.general'));
    },
  });

  return (
    <Layout>
      <div className="max-w-[1065px] mx-auto">
        <div className="sm:my-6 my-3 lg:mt-10">
          <div>
            <h2 className="md:text-lg text-base font-semibold text-black">
              Friend Requests
            </h2>
            <p className="font-normal text-sm text-gray-500 leading-6">
              Manage your received and requested friend requests
            </p>
          </div>

          <nav className="nav__underline">
            <ul className="group">
              <li>
                <a href="/friend-requests/received">Received</a>
              </li>
              <li>
                <a aria-expanded="true" href="/friend-requests/requested">
                  Requested
                </a>
              </li>
              <li>
                <a href="/friend-requests/accepted">Accepted</a>
              </li>
            </ul>
          </nav>
        </div>

        {getRequestedFriendRequestsQuery.isLoading && <Loader />}

        <div id="group-tabs">
          <div className="grid md:grid-cols-2 gap-4">
            {getRequestedFriendRequestsQuery?.data?.map((friendship, idx) => (
              <div
                key={idx}
                className="flex md:items-center space-x-4 p-4 shadow-md dark:shadow-slate-950 rounded-lg"
              >
                <a
                  href={`/user/${friendship.user.id}`}
                  className="sm:w-20 w-14 sm:h-20 h-14 flex-shrink-0 rounded-lg relative"
                >
                  <Avatar
                    userId={friendship.user?.id as string}
                    username={friendship.user?.username}
                  />
                </a>
                <a href={`/user/${friendship.user.id}`} className="flex-1">
                  <div className="md:text-lg text-base font-semibold capitalize text-black dark:text-white">
                    {friendship.user.username}
                  </div>

                  <div className="text-sm text-gray-500 line-clamp-2">
                    {friendship.user?.games
                      .map((game) => game.title)
                      .join(', ')}
                  </div>
                </a>
                <div>
                  <button
                    type="button"
                    className="button border dark:border-gray-600/60 dark:text-slate-50 text-slate-800 my-auto"
                  >
                    <IonIcon icon={ellipsisHorizontal} className="text-lg" />
                  </button>
                  <div
                    className="w-[240px] uk-dropdown"
                    data-uk-dropdown="pos: bottom-right; animation: uk-animation-scale-up uk-transform-origin-top-right; animate-out: true; mode: click;offset:10"
                  >
                    <nav>
                      <a
                        className="uk-dropdown-close cursor-pointer"
                        data-uk-toggle="target: #remove-friend-request"
                        onClick={() => setFriendship(friendship)}
                      >
                        <IonIcon icon={trashOutline} className="text-xl" />
                        Remove
                      </a>
                      <a
                        className="uk-dropdown-close cursor-pointer"
                        data-uk-toggle="target: #report-user"
                        onClick={() => setFriendship(friendship)}
                      >
                        <IonIcon icon={flagOutline} className="text-xl" />
                        Report
                      </a>
                      <hr />
                      <a
                        data-uk-toggle="target: #block-user"
                        onClick={() => setFriendship(friendship)}
                        className="uk-dropdown-close cursor-pointer text-red-400 hover:!bg-red-50 dark:hover:!bg-red-500/50"
                      >
                        <IonIcon icon={stopCircleOutline} className="text-xl" />
                        Block
                      </a>
                    </nav>
                  </div>
                </div>
              </div>
            ))}

            <RemoveFriendRequestModal friendship={friendship} />

            <ReportUserModal userId={friendship?.user?.id} />

            <BlockUserModal userId={friendship?.user?.id} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RequestedFriendRequests;
