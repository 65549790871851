import Api from '../utils/api';
import { IDiscoveredGame } from '../interfaces/discovered-game.interface';

export const GameService = {
  search: async function (title: string): Promise<IDiscoveredGame[]> {
    const { data } = await Api.get<IDiscoveredGame[]>(
      `/game/search?title=${title}`,
    );

    return data;
  },
};
