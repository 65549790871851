import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { OrderService } from '../services/OrderService';
import { LanguageCode } from '../enums/language-code.enum';
import ErrorToast from '../components/ErrorToast';
import { IStandardGameKey } from '../interfaces/standard-game-key.interface';
import Loader from '../components/Loader';
import { getImage } from '../utils/image';
import { RingLoader } from 'react-spinners';
import AccountMenu from '../components/AccountMenu';
import { StandardKeyDeliveryStatus } from '../enums/standard-key-delivery-status.enum';
import { IProfile } from '../interfaces/profile.interface';
import { UserService } from '../services/UserService';

const PurchasedProducts = () => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const [standardGameKey, setStandardGameKey] = useState<IStandardGameKey>();

  const [profile, setProfile] = useState<IProfile>();

  const getProfileQuery = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      return await UserService.getProfile();
    },
    onSuccess: async (profile) => {
      setProfile(profile);
    },
    onError: async () => {
      ErrorToast(t('Errors.general'));
    },
  });

  const purchasedStandardGameKeysQuery = useQuery({
    queryKey: ['standardGameKeys'],
    queryFn: async () => {
      return await OrderService.getStandardUserGameKeys(LanguageCode.EN);
    },
    onError: async () => {
      ErrorToast(t('Errors.general'));
    },
  });

  const revealStandardGameKeyMutation = useMutation(
    ({ standardGameKey }: { standardGameKey: IStandardGameKey }) => {
      return OrderService.revealStandardUserGameKey(
        standardGameKey.id,
        LanguageCode.EN,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('standardGameKeys');
      },
      onError: () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  useEffect(() => {
    if (standardGameKey?.revealed) {
      void revealStandardGameKeyMutation.mutate({
        standardGameKey: standardGameKey,
      });
    }
  }, [standardGameKey]);

  return (
    <Layout>
      <>
        {(getProfileQuery.isLoading ||
          purchasedStandardGameKeysQuery.isLoading) && <Loader />}

        {profile && (
          <div className="max-w-[1065px] mx-auto">
            <div className="relative">
              <AccountMenu user={profile} activeTab="accountProducts" />

              <div className="md:py-12 py-6 overflow-hidden text-black dark:text-white text-sm">
                {purchasedStandardGameKeysQuery.data && (
                  <>
                    {purchasedStandardGameKeysQuery?.data?.length === 0 ? (
                      <div className="text-center">
                        <h3 className="">
                          {t('PurchasedProducts.noPurchases')}
                        </h3>
                        <a
                          href="https://store.playsum.live"
                          target="_blank"
                          className="mt-3 button border dark:border-gray-600/60 py-1 px-2 sm:py-1.5 sm:px-4 dark:text-slate-50 text-slate-800 w-48"
                          rel="noreferrer"
                        >
                          {t('PurchasedProducts.goToStoreButton')}
                        </a>
                      </div>
                    ) : (
                      <div className="grid lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-4 grid-cols-2 gap-4">
                        {purchasedStandardGameKeysQuery.data.map(
                          (standardGameKey, idx) => {
                            return (
                              <div key={idx}>
                                <div className="shadow-md dark:shadow-slate-950 rounded-lg">
                                  <div>
                                    <div className="aspect-auto bg-bgbody">
                                      <img
                                        className="object-cover"
                                        src={getImage(
                                          standardGameKey.product?.coverImage,
                                          'packshot',
                                        )}
                                        alt={`${standardGameKey.product.name} Cover`}
                                      />
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <div>
                                      <h4 className="card-title text-sm line-clamp-1">
                                        {standardGameKey.product.name}
                                      </h4>
                                      <p className="text-neutral-500 dark:text-neutral-400 line-clamp-1">
                                        {standardGameKey.product.platforms
                                          .map((platform) =>
                                            // @ts-ignore
                                            t(`Platforms.${platform}`),
                                          )
                                          .join(', ')}
                                      </p>
                                      {standardGameKey.deliveryStatus ===
                                      StandardKeyDeliveryStatus.DELIVERED ? (
                                        <button
                                          className="button border dark:border-gray-600/60 py-1 px-2 sm:py-1.5 sm:px-4 dark:text-slate-50 text-slate-800"
                                          data-uk-toggle="target: #reveal-key"
                                          onClick={() =>
                                            setStandardGameKey(standardGameKey)
                                          }
                                        >
                                          {standardGameKey.revealed
                                            ? t('PurchasedProducts.showKeys')
                                            : t('PurchasedProducts.revealKeys')}
                                        </button>
                                      ) : (
                                        <button
                                          disabled
                                          className="button py-1 px-2 sm:py-1.5 sm:px-4 dark:text-slate-50 text-slate-800 cursor-default"
                                        >
                                          {t(
                                            `DeliveryStatus.${standardGameKey.deliveryStatus}`,
                                          )}
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          },
                        )}
                      </div>
                    )}

                    <div id="reveal-key-modal-wrapper">
                      <div
                        className="hidden lg:p-20 uk- open"
                        id="reveal-key"
                        data-uk-modal="container: #reveal-key-modal-wrapper;"
                      >
                        <div className="uk-modal-dialog tt relative overflow-hidden mx-auto bg-white shadow-xl rounded-lg md:w-[520px] w-full dark:bg-dark2 text-black dark:text-white">
                          <div className="text-center py-4 border-b mb-0 dark:border-slate-700">
                            <div className="text-base font-semibold text-black dark:text-white px-10">
                              {standardGameKey?.product.name}
                            </div>
                            <button
                              type="button"
                              className="button-icon absolute top-0 right-0 m-2.5 uk-modal-close"
                              onClick={() => setStandardGameKey(undefined)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                          </div>
                          {revealStandardGameKeyMutation.isLoading ? (
                            <RingLoader
                              color={'#db00ff'}
                              size={70}
                              speedMultiplier={0.8}
                              className="mx-auto my-5"
                            />
                          ) : (
                            <>
                              {revealStandardGameKeyMutation.data ? (
                                <div className="p-5 text-sm">
                                  {revealStandardGameKeyMutation.data.map(
                                    (revealedGameKey, index) => {
                                      return (
                                        <div
                                          className="mb-4 last:mb-0"
                                          key={index}
                                        >
                                          <div className="text-sm text-center">
                                            {revealedGameKey.name}
                                          </div>
                                          <div className="mt-2 text-sm font-semibold text-center bg-slate-50 dark:bg-slate-900 text-neutral-6000 dark:text-white rounded-lg p-2.5 pl-5">
                                            {revealedGameKey.key}
                                          </div>
                                        </div>
                                      );
                                    },
                                  )}
                                  {standardGameKey?.product?.instructions && (
                                    <div className="mb-4">
                                      <div className="text-sm text-center">
                                        Instructions
                                      </div>
                                      <div className="mt-2 text-sm bg-slate-50 dark:bg-slate-900 text-neutral-6000 dark:text-white rounded-lg p-2.5 pl-5">
                                        {standardGameKey?.product?.instructions}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <>
                                  <div className="p-5 text-sm">
                                    <p className="mb-1 font-bold">
                                      {t('PurchasedProducts.revealKeys')}
                                    </p>

                                    <Trans i18nKey="PurchasedProducts.revealKeysQuestion" />
                                  </div>
                                  <div className="px-5 pb-5 flex justify-between items-center">
                                    <div className="flex items-center gap-3">
                                      <button
                                        type="button"
                                        className="button py-2 px-12 text-[14px] bg-slate-900 dark:bg-slate-100 text-slate-50 dark:text-slate-800"
                                        onClick={() => {
                                          if (standardGameKey) {
                                            void revealStandardGameKeyMutation.mutate(
                                              {
                                                standardGameKey:
                                                  standardGameKey,
                                              },
                                            );
                                          }
                                        }}
                                      >
                                        Yes
                                      </button>
                                      <button
                                        type="button"
                                        className="button py-2 px-12 text-[14px] border dark:border-gray-600/60 dark:text-slate-50 text-slate-800 uk-modal-close"
                                        onClick={() =>
                                          setStandardGameKey(undefined)
                                        }
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    </Layout>
  );
};

export default PurchasedProducts;
