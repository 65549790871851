import React from 'react';
import { useAuthStore } from '../store/zustand';
import { useMutation } from 'react-query';
import { UserService } from '../services/UserService';
import * as yup from 'yup';
import { Formik } from 'formik';
import Loader from '../components/Loader';
import ErrorToast from '../components/ErrorToast';
import PublicLayout from '../components/PublicLayout';
import { Link, useLocation } from 'react-router-dom';
import ButtonPrimary from '../components/Button/ButtonPrimary';
import { Trans, useTranslation } from 'react-i18next';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

const Login = () => {
  const { search } = useLocation();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = React.useState(false);
  const setLoggedIn = useAuthStore((state) => state.setLoggedIn);

  const loginMutation = useMutation(
    ({ email, password }: { email: string; password: string }) => {
      return UserService.login(email, password);
    },
    {
      onSuccess: async ({ user }) => {
        setLoggedIn(user);
      },
      onError: () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  return (
    <PublicLayout>
      <>
        {loginMutation.isLoading && <Loader />}

        <div>
          <h2 className="text-2xl font-semibold mb-1.5">
            {t('Login.headline')}
          </h2>
          <p className="text-sm font-normal">
            <Trans
              i18nKey="Login.subheadline"
              components={{
                anchor: (
                  <Link to={`/register${search}`} className="text-[#db00ff]" />
                ),
              }}
            />
          </p>
        </div>

        <Formik
          enableReinitialize={true}
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={yup.object({
            email: yup
              .string()
              .trim()
              .lowercase()
              .email(t('FormValidations.email'))
              .required(t('FormValidations.emailRequired')),
            password: yup
              .string()
              .trim()
              .required(t('FormValidations.passwordRequired')),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            loginMutation.mutate({
              email: values.email,
              password: values.password,
            });

            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              className="space-y-7 text-sm text-black font-medium dark:text-white"
              onSubmit={handleSubmit}
            >
              <div>
                <label htmlFor="email" className="">
                  {t('FormLabels.email')}
                </label>
                <div className="mt-2.5">
                  <input
                    id="email"
                    type="email"
                    placeholder="example@example.com"
                    className="w-full"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && (
                    <p className="text-red-500 text-xs italic mt-1">
                      {errors.email}
                    </p>
                  )}{' '}
                </div>
              </div>

              <div>
                <label htmlFor="password" className="">
                  {t('FormLabels.password')}
                </label>
                <div className="mt-2.5">
                  <div className="flex">
                    <input
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      className="w-full"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span
                      className="flex justify-around items-center"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <IonIcon
                        icon={showPassword ? eyeOutline : eyeOffOutline}
                        className="absolute mr-10 cursor-pointer text-lg"
                      />
                    </span>
                  </div>
                  {errors.password && (
                    <p className="text-red-500 text-xs italic mt-1">
                      {errors.password}
                    </p>
                  )}
                </div>

                <div className="text-right mt-4">
                  <Link
                    className="text-sm text-[#db00ff]"
                    to={`/forgot-password${search}`}
                  >
                    {t('Login.forgotPassword')}
                  </Link>
                </div>
              </div>

              <div>
                <ButtonPrimary disabled={isSubmitting}>
                  {t('Login.loginAction')}
                </ButtonPrimary>
              </div>
            </form>
          )}
        </Formik>
      </>
    </PublicLayout>
  );
};

export default Login;
