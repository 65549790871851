import React from 'react';
import { DateSeparatorProps } from 'stream-chat-react';

const CustomDateSeparator = (props: DateSeparatorProps) => {
  const { date: messageCreatedAt } = props;

  return (
    <div className="flex justify-center my-6">
      <div className="font-medium text-gray-500 text-sm dark:text-white/70">
        {messageCreatedAt.toLocaleString()}
      </div>
    </div>
  );
};

export default CustomDateSeparator;
