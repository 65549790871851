import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { UserService } from '../services/UserService';
import * as yup from 'yup';
import { Formik } from 'formik';
import Loader from '../components/Loader';
import ErrorToast from '../components/ErrorToast';
import PublicLayout from '../components/PublicLayout';
import ButtonPrimary from '../components/Button/ButtonPrimary';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

const ResendVerification = () => {
  const { search } = useLocation();
  const [searchParams] = useSearchParams();

  const { t } = useTranslation();

  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState<string>();

  const resendVerificationMutation = useMutation(
    ({ email }: { email: string }) => {
      return UserService.resendVerification(
        email,
        searchParams.has('redirect_to')
          ? (searchParams.get('redirect_to') as string)
          : undefined,
      );
    },
    {
      onSuccess: async () => {
        setSuccess(true);
      },
      onError: () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  return (
    <PublicLayout>
      <>
        {resendVerificationMutation.isLoading && <Loader />}

        {success ? (
          <div>
            <h2 className="text-2xl font-semibold mb-5">
              {t('ResendVerification.success.headline')}
            </h2>

            <p className="text-sm font-normal mb-3">
              <Trans
                i18nKey="ResendVerification.success.firstParagraph"
                values={{ email: email }}
                components={{
                  important: <b />,
                }}
              />
            </p>

            <p className="text-sm font-normal mb-3">
              <Trans
                i18nKey="ResendVerification.success.secondParagraph"
                components={{
                  important: <b />,
                }}
              />
            </p>

            <p className="text-sm font-normal">
              <Trans
                i18nKey="ResendVerification.success.thirdParagraph"
                components={{
                  anchor: (
                    <Link
                      to={`${search}`}
                      onClick={() =>
                        email &&
                        resendVerificationMutation.mutate({ email: email })
                      }
                      className="text-[#db00ff]"
                    />
                  ),
                }}
              />
            </p>

            <div className="mt-7">
              <ButtonPrimary href={`/login${search}`}>
                {t('ResendVerification.success.backToLoginAction')}
              </ButtonPrimary>
            </div>
          </div>
        ) : (
          <>
            <div>
              <h2 className="text-2xl font-semibold mb-1.5">
                {t('ResendVerification.headline')}
              </h2>

              <p className="text-sm font-normal">
                {t('ResendVerification.subheadline')}
              </p>
            </div>

            <Formik
              enableReinitialize={true}
              initialValues={{
                email: '',
              }}
              validationSchema={yup.object({
                email: yup
                  .string()
                  .trim()
                  .lowercase()
                  .email(t('FormValidations.email'))
                  .required(t('FormValidations.emailRequired')),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                setSuccess(false);
                setEmail(values.email);

                resendVerificationMutation.mutate({
                  email: values.email,
                });

                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form
                  className="space-y-7 text-sm text-black font-medium dark:text-white"
                  onSubmit={handleSubmit}
                >
                  <div>
                    <label htmlFor="email" className="">
                      {t('FormLabels.email')}
                    </label>
                    <div className="mt-2.5">
                      <input
                        id="email"
                        type="email"
                        placeholder="example@example.com"
                        className="w-full"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && (
                        <p className="text-red-500 text-xs italic mt-1">
                          {errors.email}
                        </p>
                      )}{' '}
                    </div>
                  </div>

                  <div>
                    <ButtonPrimary disabled={isSubmitting}>
                      {t('ResendVerification.resendVerificationEmailAction')}
                    </ButtonPrimary>
                  </div>
                </form>
              )}
            </Formik>
          </>
        )}
      </>
    </PublicLayout>
  );
};

export default ResendVerification;
