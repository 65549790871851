import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import ErrorToast from './ErrorToast';
import { UserService } from '../services/UserService';
import { ReportReason } from '../enums/report-reason.enum';
import Loader from './Loader';

const ReportUserModal = ({ userId }: { userId?: string }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [reportReason, setReportReason] = useState<ReportReason>(
    ReportReason.ILLEGAL,
  );

  const [additionalInformation, setAdditionalInformation] = useState<
    string | undefined
  >();

  const reportUserMutation = useMutation(
    ({
      id,
      reportReason,
      additionalInformation,
    }: {
      id: string;
      reportReason: ReportReason;
      additionalInformation?: string;
    }) => {
      return UserService.reportUser(id, reportReason, additionalInformation);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['receivedFriendRequests']);
      },
      onError: (e: any) => {
        if (e?.response?.status === 409) {
          ErrorToast(t('Errors.userAlreadyReported'));
        } else {
          ErrorToast(t('Errors.general'));
        }
      },
    },
  );

  return (
    <div id="report-user-modal-wrapper">
      {reportUserMutation.isLoading && <Loader />}

      <div
        className="hidden lg:p-20 uk- open"
        id="report-user"
        data-uk-modal="container: #report-user-modal-wrapper;"
      >
        {userId && (
          <div className="uk-modal-dialog tt relative overflow-hidden mx-auto bg-white shadow-xl rounded-lg md:w-[520px] w-full dark:bg-dark2 text-black dark:text-white">
            <div className="text-center py-4 border-b mb-0 dark:border-slate-700">
              <div className="text-base font-semibold text-black dark:text-white px-10">
                Report User
              </div>
              <button
                type="button"
                className="button-icon absolute top-0 right-0 m-2.5 uk-modal-close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <>
              <div className="p-5 text-sm">
                Why do you want to report this user?
                <select
                  className="mt-3 w-full dark:bg-slate-800"
                  onChange={(e) =>
                    setReportReason(e.target.value as ReportReason)
                  }
                  value={reportReason}
                >
                  <option
                    key={ReportReason.ILLEGAL}
                    value={ReportReason.ILLEGAL}
                  >
                    Illegal
                  </option>
                  <option
                    key={ReportReason.INAPPROPRIATE_BEHAVIOUR}
                    value={ReportReason.INAPPROPRIATE_BEHAVIOUR}
                  >
                    Inappropriate behaviour
                  </option>
                  <option
                    key={ReportReason.IN_DANGER}
                    value={ReportReason.IN_DANGER}
                  >
                    In danger
                  </option>
                  <option key={ReportReason.SPAM} value={ReportReason.SPAM}>
                    Spam
                  </option>
                  <option key={ReportReason.OTHER} value={ReportReason.OTHER}>
                    Other
                  </option>
                </select>
              </div>

              <div className="px-5 pb-5 text-sm">
                Additional information
                <textarea
                  className="mt-3 w-full dark:bg-slate-800"
                  onChange={(e) => {
                    if (e.target.value.trim() !== '') {
                      setAdditionalInformation(e.target.value);
                    } else {
                      setAdditionalInformation(undefined);
                    }
                  }}
                ></textarea>
              </div>

              <div className="px-5 pb-5 flex justify-between items-center">
                <div className="flex items-center gap-3">
                  <button
                    type="button"
                    className="button py-2 px-12 text-[14px] bg-slate-900 dark:bg-slate-100 text-slate-50 dark:text-slate-800 uk-modal-close"
                    onClick={() => {
                      void reportUserMutation.mutate({
                        id: userId,
                        reportReason: reportReason,
                        additionalInformation: additionalInformation,
                      });
                    }}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="button py-2 px-12 text-[14px] border dark:border-gray-600/60 dark:text-slate-50 text-slate-800 uk-modal-close"
                  >
                    No
                  </button>
                </div>
              </div>
            </>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportUserModal;
