import Cookies from 'js-cookie';
import { StateStorage } from 'zustand/middleware';

export const cookieStorage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    return Cookies.get(name) || null;
  },
  setItem: async (name: string, value: string): Promise<void> => {
    Cookies.set(name, value, {
      expires: 365,
      sameSite: 'strict',
      secure: process.env.NODE_ENV !== 'development',
      domain:
        process.env.NODE_ENV === 'production'
          ? window.location.hostname.replace('app', '')
          : 'localhost',
    });
  },
  removeItem: async (name: string): Promise<void> => {
    Cookies.remove(name);
  },
};
