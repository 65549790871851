import { useEffect, useState } from 'react';
import { StreamChat } from 'stream-chat';
import { useAuthStore } from '../store/zustand';
import { ChatService } from '../services/ChatService';
import { UserService } from '../services/UserService';

export const useChatClient = () => {
  const user = useAuthStore((state) => state.user);

  const [chatClient, setChatClient] = useState<StreamChat | null>(null);

  useEffect(() => {
    if (user) {
      const client = new StreamChat(process.env.REACT_APP_GETSTREAM_KEY || '');
      let didUserConnectInterrupt = false;

      const connectionPromise = client
        .connectUser(
          {
            id: user.id,
            name: user.username,
          },
          async () => {
            try {
              return await ChatService.getToken();
            } catch (e) {
              await UserService.logout();

              throw e;
            }
          },
        )
        .then(() => {
          if (!didUserConnectInterrupt) setChatClient(client);
        });

      return () => {
        didUserConnectInterrupt = true;
        setChatClient(null);
        connectionPromise.then(() => client.disconnectUser());
      };
    }
  }, [user]);

  return chatClient;
};
