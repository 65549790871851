import React, { useEffect } from 'react';
import Loader from '../components/Loader';
import { useAuthStore } from '../store/zustand';
import { useMutation } from 'react-query';
import { UserService } from '../services/UserService';
import { useSearchParams } from 'react-router-dom';
const Logout = () => {
  const [searchParams] = useSearchParams();

  const setLoggedOut = useAuthStore((state) => state.setLoggedOut);

  const logoutMutation = useMutation(
    () => {
      return UserService.logout();
    },
    {
      onSuccess: async () => {
        setLoggedOut();

        if (searchParams.has('redirect_to')) {
          window.location.replace(searchParams.get('redirect_to') as string);
        } else {
          window.location.replace('/login');
        }
      },
    },
  );

  useEffect(() => {
    logoutMutation.mutate();
  }, []);

  return <Loader />;
};

export default Logout;
