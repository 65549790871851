import Api from '../utils/api';
import { LanguageCode } from '../enums/language-code.enum';
import { IStandardGameKey } from '../interfaces/standard-game-key.interface';
import { IRevealedStandardGameKey } from '../interfaces/revealed-standard-game-key.interface';
import { IOrder } from '../interfaces/order.interface';

export const OrderService = {
  getOrders: async function (languageCode: LanguageCode): Promise<IOrder[]> {
    const { data } = await Api.get<IOrder[]>(
      `/shop/order/profile?language=${languageCode}`,
    );

    return data;
  },
  getStandardUserGameKeys: async function (
    languageCode: LanguageCode,
  ): Promise<IStandardGameKey[]> {
    const { data } = await Api.get<IStandardGameKey[]>(
      `/shop/game-key/standard/profile?language=${languageCode}`,
    );

    return data;
  },
  revealStandardUserGameKey: async function (
    keyId: string,
    languageCode: LanguageCode,
  ): Promise<IRevealedStandardGameKey[]> {
    const { data } = await Api.get<IRevealedStandardGameKey[]>(
      `/shop/game-key/standard/reveal/${keyId}?language=${languageCode}`,
    );

    return data;
  },
};
