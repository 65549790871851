import {
  chevronBackOutline,
  flagOutline,
  stopCircleOutline,
} from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import React from 'react';
import { useChannelStateContext } from 'stream-chat-react';
import { DefaultGenerics, StreamChat } from 'stream-chat';
import ReportUserModal from './ReportUserModal';
import BlockUserModal from './BlockUserModal';
import { Link } from 'react-router-dom';
import Avatar from './Avatar';

const DirectChannelMessageHeader = ({
  client,
}: {
  client: StreamChat<DefaultGenerics>;
}) => {
  const { channel } = useChannelStateContext();

  const otherUserIds = Object.keys(channel.state.members).filter(
    (key) => key !== client.userID,
  );

  const otherUser = channel.state.members[otherUserIds[0]]?.user;

  return (
    <>
      <div className="flex items-center justify-between gap-2 w- px-6 py-3.5 z-10 border-b dark:border-slate-800 uk-animation-slide-top-medium">
        <div className="flex items-center sm:gap-4 gap-2">
          <button
            type="button"
            className="md:hidden"
            data-uk-toggle="target: #side-chat ; cls: max-md:-translate-x-full"
          >
            <IonIcon icon={chevronBackOutline} className="text-2xl -ml-4" />
          </button>

          <Link
            to={`/user/${otherUser?.id}`}
            className="relative cursor-pointer max-md:hidden"
          >
            <div className="flex w-8 h-8">
              <Avatar
                userId={otherUser?.id as string}
                username={otherUser?.name}
              />
            </div>
          </Link>
          <Link to={`/user/${otherUser?.id}`} className="cursor-pointer">
            <div className="text-base font-bold">{otherUser?.name}</div>
            {otherUser?.online ? (
              <div className="text-xs text-green-500 font-semibold">Online</div>
            ) : (
              <div className="text-xs text-gray-500 font-semibold">Offline</div>
            )}
          </Link>
        </div>

        <div className="flex items-center gap-2">
          <button type="button" className="p-1.5 rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
          </button>
          <div
            className="md:w-[270px] w-full"
            data-uk-dropdown="pos: bottom-left; offset:10; animation: uk-animation-slide-bottom-small"
          >
            <nav className="cursor-pointer">
              <a data-uk-toggle="target: #report-user">
                <IonIcon
                  icon={flagOutline}
                  className="text-2xl shrink-0 -ml-1"
                />
                Report
              </a>
              <a data-uk-toggle="target: #block-user">
                <IonIcon
                  icon={stopCircleOutline}
                  className="text-2xl shrink-0 -ml-1"
                />
                Block
              </a>
            </nav>
          </div>
        </div>
      </div>

      <ReportUserModal userId={otherUser?.id} />

      <BlockUserModal userId={otherUser?.id} />
    </>
  );
};

export default DirectChannelMessageHeader;
