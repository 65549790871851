import Api from '../utils/api';
import { IAffiliateSale } from '../interfaces/affiliate-sale.interface';
import { IAffiliateSalesOverview } from '../interfaces/affiliate-sales-overview.interface';

export const AffiliateSaleService = {
  getUserAffiliateSalesOverview:
    async function (): Promise<IAffiliateSalesOverview> {
      const { data } = await Api.get<IAffiliateSalesOverview>(
        `/shop/affiliate/profile/overview`,
      );

      return data;
    },
  requestUserAffiliateSalesPayout: async function (
    paypal: string,
  ): Promise<void> {
    await Api.post<void>(`/shop/affiliate/profile/payout`, {
      paypal: paypal,
    });
  },
  getUserAffiliateSales: async function ({
    queryKey = [],
    pageParam = 0,
  }): Promise<{ affiliateSales: IAffiliateSale[]; offset: number }> {
    if (!pageParam) {
      pageParam = 0;
    }

    let url = `/shop/affiliate/profile?offset=${pageParam}`;

    if (queryKey.length === 2) {
      const status = queryKey[1];

      if (status !== '') {
        url += `&status=${status}`;
      }
    }

    const { data } = await Api.get<IAffiliateSale[]>(url);

    return { affiliateSales: data, offset: pageParam };
  },
};
