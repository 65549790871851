export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  ZAR = 'ZAR',
  AUD = 'AUD',
  BGN = 'BGN',
  BRL = 'BRL',
  CAD = 'CAD',
  CHF = 'CHF',
  INR = 'INR',
  CNY = 'CNY',
  DKK = 'DKK',
  KRW = 'KRW',
  HKD = 'HKD',
  NOK = 'NOK',
  PLN = 'PLN',
  NZD = 'NZD',
  JPY = 'JPY',
  SEK = 'SEK',
  TRY = 'TRY',
  KWD = 'KWD',
  QAR = 'QAR',
  SAR = 'SAR',
  TWD = 'TWD',
  IDR = 'IDR',
  ARS = 'ARS',
  HUF = 'HUF',
  COP = 'COP',
}
