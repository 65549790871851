import React from 'react';
import { AvatarProps } from 'stream-chat-react';
import { Link } from 'react-router-dom';
import Avatar from './Avatar';

const CustomMessageAvatar = (props: AvatarProps) => {
  return (
    <Link
      to={`/user/${props?.user?.id}`}
      className="relative w-7 h-7 shrink-0 str-chat__avatar str-chat__message-sender-avatar"
    >
      <Avatar userId={props?.user?.id as string} username={props?.user?.name} />
    </Link>
  );
};

export default CustomMessageAvatar;
