import React, { ButtonHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';

type ButtonPrimaryProps = {
  className?: string;
  disabled?: boolean;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  href?: string;
  targetBlank?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
};

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  className = 'button disabled:bg-opacity-90 bg-slate-900 dark:bg-slate-100 py-2 px-3 sm:py-2.5 sm:px-5 text-slate-50 dark:text-slate-800 w-full',
  ...args
}) => {
  if (!!args?.href) {
    return (
      <Link
        to={args.href}
        className={className}
        onClick={args?.onClick}
        target={args?.targetBlank ? '_blank' : '_self'}
      >
        {args?.children}
      </Link>
    );
  }

  return <button className={className} {...args} />;
};

export default ButtonPrimary;
