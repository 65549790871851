import { StateCreator } from 'zustand';
import { IAuthUser } from '../interfaces/auth-user.interface';

export interface IAuthSlice {
  isLoggedIn: boolean;
  user?: IAuthUser;
  setLoggedIn: (user: IAuthUser) => void;
  setLoggedOut: () => void;
}

export const createAuthSlice: StateCreator<IAuthSlice> = (set) => ({
  isLoggedIn: false,
  setLoggedIn: (user: IAuthUser) =>
    set(() => ({ user: user, isLoggedIn: true })),
  setLoggedOut: () => set(() => ({ user: undefined, isLoggedIn: false })),
});
