import React, { useState } from 'react';
import Layout from '../components/Layout';
import { useTranslation } from 'react-i18next';
import Loader from '../components/Loader';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Pronoun } from '../enums/pronoun.enum';
import ButtonPrimary from '../components/Button/ButtonPrimary';
import { IProfile } from '../interfaces/profile.interface';
import { useMutation, useQuery } from 'react-query';
import { UserService } from '../services/UserService';
import ErrorToast from '../components/ErrorToast';
import AccountMenu from '../components/AccountMenu';
import SocialHandle from '../components/SocialHandle';

const Account = () => {
  const { t } = useTranslation();

  const [profile, setProfile] = useState<IProfile>();

  const getProfileQuery = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      return await UserService.getProfile();
    },
    onSuccess: async (profile) => {
      setProfile(profile);
    },
    onError: async () => {
      ErrorToast(t('Errors.general'));
    },
  });

  const updateProfileMutation = useMutation(
    (updateData: { pronoun?: Pronoun; birthday: string; bio?: string }) => {
      return UserService.updateProfile(updateData);
    },
    {
      onSuccess: async (profile) => {
        setProfile(profile);
      },
      onError: () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  return (
    <Layout>
      <>
        {(getProfileQuery.isLoading || updateProfileMutation.isLoading) && (
          <Loader />
        )}

        {profile && (
          <div className="max-w-[1065px] mx-auto">
            <div className="relative">
              <AccountMenu
                user={profile}
                activeTab="settings"
                simpleStyle={false}
              />

              <div className="md:py-12 py-6 overflow-hidden text-black dark:text-white text-sm">
                {profile && (
                  <div>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        pronoun: profile?.pronoun,
                        birthday: profile?.birthday ?? '',
                        bio: profile?.bio ?? '',
                        socialHandles: profile?.socialHandles,
                      }}
                      validationSchema={yup.object({
                        pronoun: yup
                          .string()
                          .oneOf(
                            Object.values(Pronoun),
                            t('FormValidations.pronoun'),
                          )
                          .required(t('FormValidations.pronounRequired'))
                          .trim(),
                        birthday: yup
                          .date()
                          .test(
                            'birthday',
                            t('FormValidations.birthdayMinAge'),
                            function (value) {
                              if (!value) return false;

                              const cutOffDate = new Date();
                              cutOffDate.setFullYear(
                                cutOffDate.getFullYear() - 13,
                              );
                              return value <= cutOffDate;
                            },
                          )
                          .required(t('FormValidations.birthdayRequired')),
                        bio: yup
                          .string()
                          .max(1000, t('FormValidations.bio'))
                          .trim(),
                        socialHandles: yup.object().shape({
                          xbox: yup
                            .string()
                            .matches(/^(?!.*(?:http|https|www\.)).*$/, {
                              message: t(
                                'FormValidations.socialHandles.generic',
                              ),
                            })
                            .trim(),
                          psn: yup
                            .string()
                            .matches(/^(?!.*(?:http|https|www\.)).*$/, {
                              message: t(
                                'FormValidations.socialHandles.generic',
                              ),
                            })
                            .trim(),
                          steam: yup
                            .string()
                            .matches(/^(?!.*(?:http|https|www\.)).*$/, {
                              message: t(
                                'FormValidations.socialHandles.generic',
                              ),
                            })
                            .trim(),
                          twitch: yup
                            .string()
                            .matches(/^(?!.*(?:http|https|www\.)).*$/, {
                              message: t(
                                'FormValidations.socialHandles.generic',
                              ),
                            })
                            .trim(),
                          youtube: yup
                            .string()
                            .matches(/^(?!.*(?:http|https|www\.)).*$/, {
                              message: t(
                                'FormValidations.socialHandles.generic',
                              ),
                            })
                            .matches(/^@.*$/, {
                              message: t(
                                'FormValidations.socialHandles.youtube',
                              ),
                            })
                            .trim(),
                        }),
                      })}
                      onSubmit={async (values, { setSubmitting }) => {
                        updateProfileMutation.mutate(values);

                        setSubmitting(false);
                      }}
                    >
                      {({
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="space-y-6">
                            <div className="md:flex items-center gap-10">
                              <label className="md:w-32 text-right font-bold">
                                {t('FormLabels.general')}
                              </label>
                            </div>

                            <div className="md:flex items-center gap-10">
                              <label
                                htmlFor="pronoun"
                                className="md:w-32 text-right"
                              >
                                {t('FormLabels.pronoun')}
                              </label>
                              <div className="flex-1 max-md:mt-4">
                                <select
                                  id="pronoun"
                                  className="w-full"
                                  value={values.pronoun}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option value="invalid"></option>
                                  {Object.keys(Pronoun).map((pronoun) => {
                                    return (
                                      <option key={pronoun} value={pronoun}>
                                        {/*@ts-ignore*/}
                                        {t(`Pronouns.${pronoun}`)}
                                      </option>
                                    );
                                  })}
                                </select>
                                {errors.pronoun && (
                                  <p className="text-red-500 text-xs italic mt-1">
                                    {errors.pronoun}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="md:flex items-center gap-10">
                              <label
                                htmlFor="birthday"
                                className="md:w-32 text-right"
                              >
                                {t('FormLabels.birthday')}
                              </label>
                              <div className="flex-1 max-md:mt-4">
                                <input
                                  id="birthday"
                                  type="date"
                                  className="w-full"
                                  value={values.birthday}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.birthday && (
                                  <p className="text-red-500 text-xs italic mt-1">
                                    {errors.birthday}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="md:flex items-center gap-10">
                              <label
                                htmlFor="bio"
                                className="md:w-32 text-right"
                              >
                                {t('FormLabels.bio')}
                              </label>
                              <div className="flex-1 max-md:mt-4">
                                <textarea
                                  id="bio"
                                  className="w-full"
                                  rows={5}
                                  value={values.bio}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.bio && (
                                  <p className="text-red-500 text-xs italic mt-1">
                                    {errors.bio}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="md:flex items-center gap-10">
                              <label className="md:w-32 text-right font-bold">
                                {t('FormLabels.socialHandles')}
                              </label>
                            </div>

                            {['xbox', 'psn', 'steam', 'twitch', 'youtube'].map(
                              (handle) => {
                                return (
                                  <div
                                    key={handle}
                                    className="md:flex items-center gap-10"
                                  >
                                    <label
                                      htmlFor={handle}
                                      className="md:w-32 text-right"
                                    >
                                      {t(
                                        // @ts-ignore
                                        `SocialHandles.${handle}`,
                                      )}
                                    </label>
                                    <div className="flex-1 max-md:mt-4">
                                      <input
                                        id={`socialHandles.${handle}`}
                                        className="w-full"
                                        type="text"
                                        value={
                                          // @ts-ignore
                                          values.socialHandles?.[handle]
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      {/*@ts-ignore*/}
                                      {values.socialHandles?.[handle] &&
                                        // @ts-ignore
                                        !errors.socialHandles?.[handle] && (
                                          <div className="text-xs italic text-neutral-500 dark:text-neutral-400 mt-1">
                                            {handle === 'psn' ? (
                                              <>
                                                Links not supported for{' '}
                                                {/*@ts-ignore*/}
                                                {t(`SocialHandles.${handle}`)}
                                              </>
                                            ) : (
                                              <SocialHandle
                                                socialHandle={handle}
                                                socialHandleValue={
                                                  // @ts-ignore
                                                  values.socialHandles?.[handle]
                                                }
                                                displayLink={true}
                                              />
                                            )}
                                          </div>
                                        )}
                                      {/*@ts-ignore*/}
                                      {errors.socialHandles?.[handle] && (
                                        <p className="text-red-500 text-xs italic mt-1">
                                          {/*@ts-ignore*/}
                                          {errors.socialHandles?.[handle]}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                );
                              },
                            )}
                          </div>

                          <div className="flex items-center mt-16 lg:pl-[10.5rem] w-full">
                            <ButtonPrimary disabled={isSubmitting}>
                              {t('Account.save')}
                            </ButtonPrimary>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    </Layout>
  );
};

export default Account;
