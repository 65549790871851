import { useThemeMode } from '../hooks/useThemeMode';
import { IonIcon } from '@ionic/react';
import { sunny, sunnyOutline } from 'ionicons/icons';

const ThemeSwitch = () => {
  const { _toogleDarkMode, isDarkMode } = useThemeMode();

  return (
    <div className="fixed bottom-6 right-6 z-50">
      <button
        className="rounded-lg shadow-sm bg-white dark:bg-dark2 dark:text-white text-black font-bold py-2 px-4"
        onClick={_toogleDarkMode}
      >
        <IonIcon
          icon={isDarkMode ? sunny : sunnyOutline}
          className="text-2xl group-aria-expanded:hidden pt-1"
        />
      </button>
    </div>
  );
};

export default ThemeSwitch;
