import React, { useEffect } from 'react';
import Loader from '../components/Loader';

type Props = {
  url: string;
};

const RedirectRoute = ({ url }: Props) => {
  useEffect(() => {
    window.location.replace(url);
  }, [url]);

  return <Loader />;
};

export default RedirectRoute;
