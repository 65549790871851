import React from 'react';
import { Link } from 'react-router-dom';

const SocialHandle = ({
  socialHandle,
  socialHandleValue,
  displayLink,
}: {
  socialHandle: string;
  socialHandleValue: string;
  displayLink?: boolean;
}) => {
  const getSocialHandleUrl = (
    socialHandle: string,
    handleValue: string,
  ): string => {
    if (socialHandle === 'xbox') {
      return `https://www.xbox.com/play/user/${handleValue}`;
    }

    if (socialHandle === 'psn') {
      return handleValue;
    }

    if (socialHandle === 'steam') {
      return `https://steamcommunity.com/id/${handleValue}`;
    }

    if (socialHandle === 'twitch') {
      return `https://www.twitch.tv/${handleValue}`;
    }

    if (socialHandle === 'youtube') {
      return `https://www.youtube.com/${handleValue}`;
    }

    return socialHandleValue;
  };

  const socialLink = getSocialHandleUrl(socialHandle, socialHandleValue);

  if (socialHandle === 'psn') {
    return <>{socialLink}</>;
  }

  return (
    <Link to={encodeURI(socialLink)} target={'_blank'}>
      {!displayLink ? socialHandleValue : socialLink}
    </Link>
  );
};

export default SocialHandle;
