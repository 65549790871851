import Api from '../utils/api';

interface ChatTokenResponse {
  token: string;
}

export const ChatService = {
  getToken: async function (): Promise<string> {
    const { data } = await Api.get<ChatTokenResponse>('/chat/user/token');

    return data.token;
  },
};
