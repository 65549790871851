import React from 'react';
import Layout from '../components/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import ErrorToast from '../components/ErrorToast';
import { useTranslation } from 'react-i18next';
import { UserService } from '../services/UserService';
import Loader from '../components/Loader';
import {
  accessibilityOutline,
  calendarClearOutline,
  cogOutline,
  ellipsisHorizontal,
  flagOutline,
  gameControllerOutline,
  logoPlaystation,
  logoSteam,
  logoTwitch,
  logoXbox,
  logoYoutube,
  personAddOutline,
  stopCircleOutline,
  timeOutline,
} from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import ReportUserModal from '../components/ReportUserModal';
import BlockUserModal from '../components/BlockUserModal';
import AddFriendRequestModal from '../components/AddFriendRequestModal';
import INoPackshot from '../assets/images/no-packshot.png';
import Avatar from '../components/Avatar';
import SocialHandle from '../components/SocialHandle';

const UserProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { userId } = useParams();

  const getUserQuery = useQuery({
    queryKey: ['userProfile', userId],
    queryFn: async () => {
      return await UserService.getUser(userId as string);
    },
    onError: async (e: any) => {
      if (e?.response?.status === 404) {
        navigate('/users');
      } else {
        ErrorToast(t('Errors.general'));
      }
    },
  });

  const getHandleIcon = (handle: string): string => {
    if (handle === 'xbox') {
      return logoXbox;
    }

    if (handle === 'psn') {
      return logoPlaystation;
    }

    if (handle === 'steam') {
      return logoSteam;
    }

    if (handle === 'twitch') {
      return logoTwitch;
    }

    if (handle === 'youtube') {
      return logoYoutube;
    }

    return '';
  };

  return (
    <Layout>
      <div className="max-w-[1065px] mx-auto">
        {getUserQuery.isLoading && <Loader />}

        {getUserQuery.data && (
          <>
            <div className="border-b border-slate-100 dark:border-slate-700">
              <div className="sm:my-6 my-3 lg:mt-10 ">
                <div className="flex flex-col justify-center">
                  <div className="flex lg:items-center justify-between max-md:flex-col gap-6">
                    <div className="flex md:w-20 md:h-20 w-14 h-14">
                      <Avatar
                        userId={getUserQuery.data?.id as string}
                        username={getUserQuery.data?.username}
                      />
                    </div>
                    <div className="flex-1">
                      <h3 className="md:text-2xl text-lg font-bold text-black dark:text-white">
                        {getUserQuery.data.username}
                      </h3>
                      <p className="text-gray-700 mt-2 text-sm dark:text-white/80">
                        {getUserQuery.data?.bio}
                      </p>
                    </div>

                    <div className="flex items-center gap-2 mt-1">
                      <div>
                        <button
                          type="button"
                          className="button border dark:border-gray-600/60 dark:text-slate-50 text-slate-800 my-auto"
                        >
                          <IonIcon
                            icon={ellipsisHorizontal}
                            className="text-lg"
                          />
                        </button>
                        <div
                          className="w-[240px] uk-dropdown"
                          data-uk-dropdown="pos: bottom-right; animation: uk-animation-scale-up uk-transform-origin-top-right; animate-out: true; mode: click;offset:10"
                        >
                          <nav>
                            {!getUserQuery.data?.isFriend && (
                              <a
                                className="uk-dropdown-close cursor-pointer"
                                data-uk-toggle="target: #add-friend-request"
                              >
                                <IonIcon
                                  icon={personAddOutline}
                                  className="text-xl"
                                />
                                Add as friend
                              </a>
                            )}
                            <a
                              className="uk-dropdown-close cursor-pointer"
                              data-uk-toggle="target: #report-user"
                            >
                              <IonIcon icon={flagOutline} className="text-xl" />
                              Report
                            </a>
                            <hr />
                            <a
                              data-uk-toggle="target: #block-user"
                              className="uk-dropdown-close cursor-pointer text-red-400 hover:!bg-red-50 dark:hover:!bg-red-500/50"
                            >
                              <IonIcon
                                icon={stopCircleOutline}
                                className="text-xl"
                              />
                              Block
                            </a>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="flex 2xl:gap-12 gap-8 mt-8 max-lg:flex-col"
              id="js-oversized"
            >
              <div className="flex-1 xl:space-y-6 space-y-3 lg:order-1 order-2">
                <div className="flex items-ce justify-between text-black dark:text-white">
                  <h3 className="font-bold text-lg">
                    {t('UserProfile.games')}
                  </h3>
                </div>

                <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 gap-4">
                  {getUserQuery.data.games.map((game, idx) => {
                    return (
                      <div key={idx}>
                        <div className="shadow-md dark:shadow-slate-950 rounded-lg">
                          <div>
                            <div className="aspect-auto bg-bgbody">
                              <img
                                className="object-cover"
                                src={`${game?.imageId ? 'https://images.igdb.com/igdb/image/upload/t_cover_big/' + game.imageId + '.jpg' : INoPackshot}`}
                                alt={`${game.title} Cover`}
                              />
                            </div>
                          </div>
                          <div className="card-body">
                            <div>
                              <h4 className="card-title text-sm line-clamp-1">
                                {game.title}
                              </h4>
                              <p className="text-neutral-500 dark:text-neutral-400 line-clamp-1">
                                {game.platforms
                                  .map((platform) =>
                                    // @ts-ignore
                                    t(`Platforms.${platform}`),
                                  )
                                  .join(', ')}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          className="uk-drop w-52 max-md:!hidden"
                          data-uk-drop="pos: center; animation: uk-animation-scale-up"
                        >
                          <div className="shadow-md dark:shadow-slate-950 rounded-lg">
                            <div className="aspect-auto bg-bgbody">
                              <img
                                className="object-cover"
                                src={`${game?.imageId ? 'https://images.igdb.com/igdb/image/upload/t_cover_big/' + game.imageId + '.jpg' : INoPackshot}`}
                                alt={`${game.title} Cover`}
                              />
                              <div className="card-overly"></div>
                            </div>
                            <div
                              className="card-body"
                              data-uk-scrollspy="target: > * ; cls: uk-animation-slide-bottom-small ; delay: 60 ;repeat: true"
                            >
                              <h4 className="card-title text-sm font-semibold">
                                {game.title}
                              </h4>
                              <p className="text-neutral-500 dark:text-neutral-400">
                                {game.platforms
                                  .map((platform) =>
                                    // @ts-ignore
                                    t(`Platforms.${platform}`),
                                  )
                                  .join(', ')}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="lg:w-[300px] lg:order-2 order-1">
                <div
                  className="lg:space-y-4 lg:pb-8 max-lg:grid sm:grid-cols-2 max-lg:gap-6"
                  data-uk-sticky="media: 1024; end: #js-oversized; offset: 80"
                >
                  <div className="pb-5">
                    <div className="flex items-ce justify-between text-black dark:text-white">
                      <h3 className="font-bold text-lg">
                        {t('UserProfile.about')}
                      </h3>
                    </div>

                    <ul className="text-gray-700 space-y-4 mt-4 text-sm dark:text-white/80">
                      {getUserQuery.data?.pronoun && (
                        <li className="flex items-center gap-3">
                          <IonIcon
                            icon={accessibilityOutline}
                            className="text-xl"
                          />
                          <div>
                            Pronoun -{' '}
                            <span className="font-semibold text-black dark:text-white">
                              {/*@ts-ignore*/}
                              {t(`Pronouns.${getUserQuery.data.pronoun}`)}
                            </span>
                          </div>
                        </li>
                      )}
                      <li className="flex items-center gap-3">
                        <IonIcon
                          icon={gameControllerOutline}
                          className="text-xl"
                        />
                        <div>
                          Games -{' '}
                          <span className="font-semibold text-black dark:text-white">
                            {getUserQuery.data.games.length}
                          </span>
                        </div>
                      </li>
                      <li className="flex items-center gap-3">
                        <IonIcon icon={cogOutline} className="text-xl" />
                        <div>
                          Platforms -{' '}
                          <span className="font-semibold text-black dark:text-white">
                            {
                              [
                                ...new Set(
                                  getUserQuery.data.games
                                    .map((game) => game.platforms)
                                    .flat(),
                                ),
                              ].length
                            }
                          </span>
                        </div>
                      </li>
                      <li className="flex items-center gap-3">
                        <IonIcon
                          icon={calendarClearOutline}
                          className="text-xl"
                        />
                        <div>
                          Member since -{' '}
                          <span className="font-semibold text-black dark:text-white">
                            {new Date(
                              getUserQuery.data.memberSince,
                            ).toLocaleDateString()}
                          </span>
                        </div>
                      </li>
                      <li className="flex items-center gap-3">
                        <IonIcon icon={timeOutline} className="text-xl" />
                        <div>
                          Last active -{' '}
                          <span className="font-semibold text-black dark:text-white">
                            {new Date(
                              getUserQuery.data?.lastActiveAt,
                            ).toLocaleString()}
                          </span>
                        </div>
                      </li>
                    </ul>

                    {Object.keys(getUserQuery.data.socialHandles).length >
                      0 && (
                      <>
                        <div className="flex items-ce justify-between text-black dark:text-white mt-8">
                          <h3 className="font-bold text-lg">
                            {t('UserProfile.socialHandles')}
                          </h3>
                        </div>

                        <ul className="text-gray-700 space-y-4 mt-4 text-sm dark:text-white/80">
                          {Object.keys(getUserQuery.data.socialHandles).map(
                            (handle) => {
                              if (
                                // @ts-ignore
                                getUserQuery.data.socialHandles[handle] !== ''
                              ) {
                                return (
                                  <li
                                    key={handle}
                                    className="flex items-center gap-3"
                                  >
                                    <IonIcon
                                      icon={getHandleIcon(handle)}
                                      className="text-xl"
                                    />
                                    <div>
                                      {t(
                                        // @ts-ignore
                                        `SocialHandles.${handle}`,
                                      )}{' '}
                                      -{' '}
                                      <span className="font-semibold text-black dark:text-white">
                                        <SocialHandle
                                          socialHandle={handle}
                                          socialHandleValue={
                                            // @ts-ignore
                                            getUserQuery.data.socialHandles[
                                              handle
                                            ]
                                          }
                                        />
                                      </span>
                                    </div>
                                  </li>
                                );
                              }
                            },
                          )}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <AddFriendRequestModal user={getUserQuery?.data} />

            <ReportUserModal userId={getUserQuery?.data?.id} />

            <BlockUserModal userId={getUserQuery?.data?.id} />
          </>
        )}
      </div>
    </Layout>
  );
};

export default UserProfile;
