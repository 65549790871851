import axios from 'axios';
import { UserService } from '../services/UserService';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { useAuthStore } from '../store/zustand';

const Api = axios.create();
Api.defaults.baseURL = Api.defaults.baseURL = process.env.REACT_APP_API_URL;

Api.defaults.withCredentials = true;

const refreshAuthLogic = () =>
  UserService.refresh()
    .then(({ user }) => {
      useAuthStore.getState().setLoggedIn(user);

      return Promise.resolve();
    })
    .catch(() => {
      UserService.logout()
        .then(() => {
          useAuthStore.getState().setLoggedOut();
        })
        .catch((e) => console.error(e));
    });

createAuthRefreshInterceptor(Api, refreshAuthLogic);

export default Api;
