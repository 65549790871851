import toast from 'react-hot-toast';

const ErrorToast = (message: string) => {
  return toast.custom(
    () => (
      <div className="w-full rounded-lg shadow-sm p-4 max-w-md bg-white dark:bg-slate-800 pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 dark:text-white text-black">
        <div className="flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 h-8 text-red-500 my-auto"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
            />
          </svg>

          <span className={`ml-2 my-auto block text-base`}>{message}</span>
        </div>
      </div>
    ),
    {
      id: message,
      position: 'top-right',
      duration: 3000,
    },
  );
};

export default ErrorToast;
