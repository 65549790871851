import React, { useState } from 'react';
import Layout from '../components/Layout';
import { useTranslation } from 'react-i18next';
import Loader from '../components/Loader';
import { getImage } from '../utils/image';
import { useQuery } from 'react-query';
import { OrderService } from '../services/OrderService';
import { LanguageCode } from '../enums/language-code.enum';
import ErrorToast from '../components/ErrorToast';
import AccountMenu from '../components/AccountMenu';
import { Link } from 'react-router-dom';
import { IProfile } from '../interfaces/profile.interface';
import { UserService } from '../services/UserService';

const Orders = () => {
  const { t } = useTranslation();

  const [profile, setProfile] = useState<IProfile>();

  const getProfileQuery = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      return await UserService.getProfile();
    },
    onSuccess: async (profile) => {
      setProfile(profile);
    },
    onError: async () => {
      ErrorToast(t('Errors.general'));
    },
  });

  const ordersQuery = useQuery({
    queryKey: ['orders'],
    queryFn: async () => {
      return await OrderService.getOrders(LanguageCode.EN);
    },
    onError: async () => {
      ErrorToast(t('Errors.general'));
    },
  });

  return (
    <Layout>
      <>
        {(getProfileQuery.isLoading || ordersQuery.isLoading) && <Loader />}

        {profile && (
          <div className="max-w-[1065px] mx-auto">
            <div className="relative">
              <AccountMenu user={profile} activeTab="accountOrders" />

              <div className="md:py-12 py-6 overflow-hidden text-black dark:text-white text-sm">
                {ordersQuery.data && (
                  <div className="font-normal text-gray-400">
                    <div className="grid gap-7">
                      {ordersQuery?.data?.length === 0 ? (
                        <div className="text-center">
                          <h3 className="">{t('Orders.noOrders')}</h3>
                          <a
                            href="https://store.playsum.live"
                            target="_blank"
                            className="mt-3 button border dark:border-gray-600/60 py-1 px-2 sm:py-1.5 sm:px-4 dark:text-slate-50 text-slate-800 w-48"
                            rel="noreferrer"
                          >
                            {t('Orders.goToStoreButton')}
                          </a>
                        </div>
                      ) : (
                        <>
                          {ordersQuery.data.map((order, idx) => {
                            return (
                              <div key={idx} className="grid gap-7">
                                <div>
                                  <h4 className="text-xl font-medium text-black dark:text-white">
                                    {t('Orders.orderNr')}: {order.orderId}
                                  </h4>
                                  <p className="mt-1 font-normal text-neutral-500 dark:text-neutral-400">
                                    <span>
                                      {new Date(
                                        order.orderDate,
                                      ).toLocaleDateString(undefined, {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                      })}
                                    </span>
                                    <span className="mx-2">·</span>
                                    <span className="capitalize">
                                      {t(
                                        `PaymentStatus.${order.paymentStatus}`,
                                      )}
                                    </span>
                                    <span className="mx-2">·</span>
                                    <Link
                                      to="https://help.playsum.live/support/tickets/new"
                                      target={'_blank'}
                                      className="text-[#db00ff]"
                                    >
                                      {t('Orders.reportProblem')}
                                    </Link>
                                  </p>
                                </div>

                                <div className="grid lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-4 grid-cols-2 gap-4">
                                  {order.items.map((orderItem, idx) => {
                                    return (
                                      <div key={idx}>
                                        <div className="shadow-md dark:shadow-slate-950 rounded-lg">
                                          <div>
                                            <div className="aspect-auto bg-bgbody">
                                              <img
                                                className="object-cover"
                                                src={getImage(
                                                  orderItem.product?.coverImage,
                                                  'packshot',
                                                )}
                                                alt={`${orderItem.product.name} Cover`}
                                              />
                                            </div>
                                          </div>
                                          <div className="card-body">
                                            <div>
                                              <h4 className="card-title text-sm line-clamp-1">
                                                {orderItem.product.name}
                                              </h4>
                                              <p className="text-neutral-500 dark:text-neutral-400 line-clamp-1">
                                                {orderItem.product.platforms
                                                  .map((platform) =>
                                                    // @ts-ignore
                                                    t(`Platforms.${platform}`),
                                                  )
                                                  .join(', ')}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>

                                {ordersQuery.data.length !== idx + 1 && (
                                  <hr className="dark:border-gray-600/60" />
                                )}
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    </Layout>
  );
};

export default Orders;
