import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { UserService } from '../services/UserService';
import PublicLayout from '../components/PublicLayout';
import Loader from '../components/Loader';
import { Formik } from 'formik';
import * as yup from 'yup';
import ErrorToast from '../components/ErrorToast';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import ButtonPrimary from '../components/Button/ButtonPrimary';
import { Trans, useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';

const Register = () => {
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = React.useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState<string>();

  const registerMutation = useMutation(
    ({
      email,
      username,
      birthday,
      password,
    }: {
      email: string;
      username: string;
      birthday: string;
      password: string;
    }) => {
      return UserService.register(
        email,
        username,
        birthday,
        password,
        searchParams.has('redirect_to')
          ? (searchParams.get('redirect_to') as string)
          : undefined,
      );
    },
    {
      onSuccess: async () => {
        setSuccess(true);
      },
      onError: (e: any) => {
        let errorMessage = t('Errors.general');

        if (e?.response?.status === 400) {
          errorMessage = t('Errors.requiredSignUpFieldsNotProvided');
        }

        if (e?.response?.status === 409) {
          errorMessage = t('Errors.accountAlreadyExists');
        }

        ErrorToast(errorMessage);
      },
    },
  );

  const resendVerificationMutation = useMutation(
    ({ email }: { email: string }) => {
      return UserService.resendVerification(
        email,
        searchParams.has('redirect_to')
          ? (searchParams.get('redirect_to') as string)
          : undefined,
      );
    },
    {
      onError: () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  return (
    <PublicLayout>
      <>
        {registerMutation.isLoading ||
          (resendVerificationMutation.isLoading && <Loader />)}

        {success ? (
          <div>
            <h2 className="text-2xl font-semibold mb-5">
              {t('ResendVerification.success.headline')}
            </h2>

            <p className="text-sm font-normal mb-3">
              <Trans
                i18nKey="ResendVerification.success.firstParagraph"
                values={{ email: email }}
                components={{
                  important: <b />,
                }}
              />
            </p>

            <p className="text-sm font-normal mb-3">
              <Trans
                i18nKey="ResendVerification.success.secondParagraph"
                components={{
                  important: <b />,
                }}
              />
            </p>

            <p className="text-sm font-normal">
              <Trans
                i18nKey="ResendVerification.success.thirdParagraph"
                components={{
                  anchor: (
                    <Link
                      to={`${search}`}
                      onClick={() =>
                        email &&
                        resendVerificationMutation.mutate({ email: email })
                      }
                      className="text-[#db00ff]"
                    />
                  ),
                }}
              />
            </p>

            <div className="mt-7">
              <ButtonPrimary href={`/login${search}`}>
                {t('ResendVerification.success.backToLoginAction')}
              </ButtonPrimary>
            </div>
          </div>
        ) : (
          <>
            <div>
              <h2 className="text-2xl font-semibold mb-1.5">
                {t('Register.headline')}
              </h2>
              <p className="text-sm font-normal">
                <Trans
                  i18nKey="Register.subheadline"
                  components={{
                    anchor: (
                      <Link to={`/login${search}`} className="text-[#db00ff]" />
                    ),
                  }}
                />
              </p>
            </div>

            <Formik
              enableReinitialize={true}
              initialValues={{
                email: '',
                username: '',
                birthday: '',
                password: '',
                passwordRepeat: '',
              }}
              validationSchema={yup.object({
                email: yup
                  .string()
                  .trim()
                  .lowercase()
                  .email(t('FormValidations.email'))
                  .required(t('FormValidations.emailRequired')),
                username: yup
                  .string()
                  .trim()
                  .min(1, t('FormValidations.usernameLength'))
                  .max(20, t('FormValidations.usernameLength'))
                  .matches(
                    /^[A-Za-z0-9][A-Za-z0-9._]{1,20}[^._]$/,
                    t('FormValidations.username'),
                  )
                  .required(t('FormValidations.usernameRequired')),
                birthday: yup
                  .date()
                  .test(
                    'birthday',
                    t('FormValidations.birthdayMinAge'),
                    function (value) {
                      if (!value) return false;

                      const cutOffDate = new Date();
                      cutOffDate.setFullYear(cutOffDate.getFullYear() - 13);
                      return value <= cutOffDate;
                    },
                  )
                  .required(t('FormValidations.birthdayRequired')),
                password: yup
                  .string()
                  .trim()
                  .min(6, t('FormValidations.passwordLength'))
                  .max(64, t('FormValidations.passwordLength'))
                  .matches(/^(\S+$)/g, t('FormValidations.whitespaces'))
                  .required(t('FormValidations.passwordRequired')),
                passwordRepeat: yup
                  .string()
                  .trim()
                  .required(t('FormValidations.passwordRequired'))
                  .min(6, t('FormValidations.passwordLength'))
                  .max(64, t('FormValidations.passwordLength'))
                  .matches(/^(\S+$)/g, t('FormValidations.whitespaces'))
                  .oneOf(
                    [yup.ref('password')],
                    t('FormValidations.passwordsNoMatch'),
                  ),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                setSuccess(false);
                setEmail(values.email);

                registerMutation.mutate({
                  email: values.email,
                  username: values.username,
                  birthday: values.birthday,
                  password: values.password,
                });

                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form
                  className="space-y-7 text-sm text-black font-medium dark:text-white"
                  onSubmit={handleSubmit}
                >
                  <div>
                    <label htmlFor="email" className="">
                      {t('FormLabels.email')}
                    </label>
                    <div className="mt-2.5">
                      <input
                        id="email"
                        type="email"
                        placeholder="example@example.com"
                        className="w-full"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && (
                        <p className="text-red-500 text-xs italic mt-1">
                          {errors.email}
                        </p>
                      )}{' '}
                    </div>
                  </div>

                  <div>
                    <label htmlFor="username" className="">
                      {t('FormLabels.username')}
                    </label>
                    <div className="mt-2.5">
                      <input
                        id="username"
                        type="text"
                        className="w-full"
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.username && (
                        <p className="text-red-500 text-xs italic mt-1">
                          {errors.username}
                        </p>
                      )}{' '}
                    </div>
                  </div>

                  <div>
                    <label htmlFor="birthday" className="">
                      {t('FormLabels.birthday')}{' '}
                    </label>
                    <div className="mt-2.5">
                      <input
                        id="birthday"
                        type="date"
                        className="w-full"
                        value={values.birthday}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.birthday && (
                        <p className="text-red-500 text-xs italic mt-1">
                          {errors.birthday}
                        </p>
                      )}{' '}
                    </div>
                  </div>

                  <div>
                    <label htmlFor="password" className="">
                      {t('FormLabels.password')}
                    </label>
                    <div className="mt-2.5">
                      <div className="flex">
                        <input
                          id="password"
                          type={showPassword ? 'text' : 'password'}
                          className="w-full"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span
                          className="flex justify-around items-center"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <IonIcon
                            icon={showPassword ? eyeOutline : eyeOffOutline}
                            className="absolute mr-10 cursor-pointer text-lg"
                          />
                        </span>
                      </div>

                      {errors.password && (
                        <p className="text-red-500 text-xs italic mt-1">
                          {errors.password}
                        </p>
                      )}
                    </div>
                  </div>

                  <div>
                    <label htmlFor="passwordRepeat" className="">
                      {t('FormLabels.repeatPassword')}{' '}
                    </label>
                    <div className="mt-2.5">
                      <div className="flex">
                        <input
                          id="passwordRepeat"
                          type={showPasswordRepeat ? 'text' : 'password'}
                          className="w-full"
                          value={values.passwordRepeat}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span
                          className="flex justify-around items-center"
                          onClick={() =>
                            setShowPasswordRepeat(!showPasswordRepeat)
                          }
                        >
                          <IonIcon
                            icon={
                              showPasswordRepeat ? eyeOutline : eyeOffOutline
                            }
                            className="absolute mr-10 cursor-pointer text-lg"
                          />
                        </span>
                      </div>
                      {errors.passwordRepeat && (
                        <p className="text-red-500 text-xs italic mt-1">
                          {errors.passwordRepeat}
                        </p>
                      )}
                    </div>

                    <div className="text-right mt-4">
                      <Link
                        className="text-sm text-[#db00ff]"
                        to={`/resend-verification${search}`}
                      >
                        {t('Register.resendVerification')}
                      </Link>
                    </div>
                  </div>

                  <div>
                    <ButtonPrimary disabled={isSubmitting}>
                      {t('Register.registerAction')}
                    </ButtonPrimary>
                  </div>
                </form>
              )}
            </Formik>
          </>
        )}
      </>
    </PublicLayout>
  );
};

export default Register;
