import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import ErrorToast from './ErrorToast';
import Loader from './Loader';
import { AffiliateSaleService } from '../services/AffiliateSaleService';
import { AffiliatePayoutError } from '../enums/affiliate-payout-error.enum';
import * as yup from 'yup';
import { Formik } from 'formik';

const RequestAffiliateSalePayoutModal = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const requestAffiliateSalePayoutMutation = useMutation(
    ({ paypal }: { paypal: string }) => {
      return AffiliateSaleService.requestUserAffiliateSalesPayout(paypal);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['affiliateSalesOverview']);
        queryClient.invalidateQueries(['affiliateSales']);
      },
      onError: (e: any) => {
        let errorMessage = t('Errors.general');

        if (
          e?.response.data?.errorCode ===
          AffiliatePayoutError.MINIMUM_PAYOUT_THRESHOLD
        ) {
          errorMessage = t('Errors.affiliatePayoutMinThreshold');
        }

        if (
          e?.response.data?.errorCode ===
          AffiliatePayoutError.PAYOUT_ALREADY_REQUESTED
        ) {
          errorMessage = t('Errors.affiliatePayoutAlreadyRequested');
        }

        ErrorToast(errorMessage);
      },
    },
  );

  return (
    <div id="request-affiliate-sale-payout-modal-wrapper">
      {requestAffiliateSalePayoutMutation.isLoading && <Loader />}

      <div
        className="hidden lg:p-20 uk- open"
        id="request-affiliate-sale-payout"
        data-uk-modal="container: #request-affiliate-sale-payout-modal-wrapper;"
      >
        <div className="uk-modal-dialog tt relative overflow-hidden mx-auto bg-white shadow-xl rounded-lg md:w-[520px] w-full dark:bg-dark2 text-black dark:text-white">
          <div className="text-center py-4 border-b mb-0 dark:border-slate-700">
            <div className="text-base font-semibold text-black dark:text-white px-10">
              Request Payout
            </div>
            <button
              type="button"
              className="button-icon absolute top-0 right-0 m-2.5 uk-modal-close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {requestAffiliateSalePayoutMutation?.isSuccess ? (
            <div className="p-5 text-sm leading-6">
              <p className="text-center mb-3 font-semibold">
                You requested a payout to your paypal account.
              </p>
              <p className="text-center">
                It takes around 3-7 business days until the funds reach your
                account. In case of questions or doubt, feel free to reach out
                to us.
              </p>
            </div>
          ) : (
            <>
              <div className="p-5 pb-5 text-sm leading-6">
                {
                  "Please enter your username, email, or mobile number associated with your PayPal account. We'll send your payment to this account via PayPal Payouts (subject to 2% transfer fee)."
                }
              </div>
              <div className="px-5 pb-5">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    paypal: '',
                    paypalRepeat: '',
                  }}
                  validationSchema={yup.object({
                    paypal: yup
                      .string()
                      .trim()
                      .required(t('FormValidations.paypalRequired')),
                    paypalRepeat: yup
                      .string()
                      .trim()
                      .oneOf(
                        [yup.ref('paypal')],
                        t('FormValidations.paypalNoMatch'),
                      ),
                  })}
                  onSubmit={async (values, { setSubmitting }) => {
                    requestAffiliateSalePayoutMutation.mutate({
                      paypal: values.paypal,
                    });

                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      className="space-y-4 text-sm text-black font-medium dark:text-white"
                    >
                      <div>
                        <label htmlFor="paypal" className="">
                          Paypal
                        </label>
                        <div className="mt-2.5">
                          <div className="flex">
                            <input
                              id="paypal"
                              type="text"
                              className="w-full !bg-transparent"
                              value={values.paypal}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>

                          {errors.paypal && (
                            <p className="text-red-500 text-xs italic mt-1.5">
                              {errors.paypal}
                            </p>
                          )}
                        </div>
                      </div>

                      <div>
                        <label htmlFor="paypalRepeat" className="">
                          Repeat Paypal
                        </label>
                        <div className="mt-2.5">
                          <div className="flex">
                            <input
                              id="paypalRepeat"
                              type="text"
                              className="w-full !bg-transparent"
                              value={values.paypalRepeat}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors.paypalRepeat && (
                            <p className="text-red-500 text-xs italic mt-1.5">
                              {errors.paypalRepeat}
                            </p>
                          )}
                        </div>
                      </div>

                      <div>
                        <button
                          type="submit"
                          className="button py-2 px-12 text-[14px] bg-slate-900 dark:bg-slate-100 text-slate-50 dark:text-slate-800 w-full"
                          disabled={isSubmitting}
                        >
                          Request Payout
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestAffiliateSalePayoutModal;
