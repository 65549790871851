import { Link } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import {
  chevronBack,
  chevronForward,
  helpCircleOutline,
  stopCircleOutline,
} from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DeleteAccountModal from './DeleteAccountModal';
import { IProfile } from '../interfaces/profile.interface';
import EditAvatarModal from './EditAvatarModal';
import Avatar from './Avatar';

const AccountMenu = ({
  user,
  activeTab,
  simpleStyle = true,
}: {
  user: IProfile;
  activeTab: 'settings' | 'accountOrders' | 'accountProducts' | 'gameLibrary';
  simpleStyle?: boolean;
}) => {
  const { t } = useTranslation();

  const menuItems = [
    {
      id: 'settings',
      name: t('Account.settings'),
      url: '/account',
    },
    {
      id: 'gameLibrary',
      name: t('Account.gameLibrary'),
      url: '/game-library',
    },
    {
      id: 'accountOrders',
      name: t('Account.orders'),
      url: '/account-orders',
    },
    {
      id: 'accountProducts',
      name: t('Account.purchasedGames'),
      url: '/account-products',
    },
  ];

  return (
    <>
      <div className="flex gap-4 items-center md:py-8 py-6 md:pb-4">
        <div className="relative md:w-20 md:h-20 w-14 h-14 shrink-0">
          {simpleStyle ? (
            <Avatar userId={user?.id as string} username={user?.username} />
          ) : (
            <>
              <label
                htmlFor="file"
                className="cursor-pointer"
                data-uk-toggle="target: #edit-avatar"
              >
                <Avatar userId={user?.id as string} username={user?.username} />
              </label>

              <label
                htmlFor="file"
                className="md:p-1 p-0.5 rounded-full bg-secondery border-4 border-bgbody absolute -bottom-2 -right-2 cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="md:w-4 md:h-4 w-3 h-3 fill-black dark:fill-white"
                >
                  <path d="M12 9a3.75 3.75 0 100 7.5A3.75 3.75 0 0012 9z" />
                  <path
                    fillRule="evenodd"
                    d="M9.344 3.071a49.52 49.52 0 015.312 0c.967.052 1.83.585 2.332 1.39l.821 1.317c.24.383.645.643 1.11.71.386.054.77.113 1.152.177 1.432.239 2.429 1.493 2.429 2.909V18a3 3 0 01-3 3h-15a3 3 0 01-3-3V9.574c0-1.416.997-2.67 2.429-2.909.382-.064.766-.123 1.151-.178a1.56 1.56 0 001.11-.71l.822-1.315a2.942 2.942 0 012.332-1.39zM6.75 12.75a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0zm12-1.5a.75.75 0 100-1.5.75.75 0 000 1.5z"
                    clipRule="evenodd"
                  />
                </svg>
              </label>

              <EditAvatarModal user={user} />
            </>
          )}
        </div>

        <div className="flex-1">
          <h3 className="md:text-xl text-base font-semibold text-black dark:text-white">
            {user.username}
          </h3>
          {!simpleStyle && (
            <p className="text-sm text-neutral-500 dark:text-neutral-400 mt-1 font-normal">
              {user.email}
            </p>
          )}
        </div>

        {!simpleStyle && (
          <div className="flex items-center">
            <button type="button" className="p-1.5 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                />
              </svg>
            </button>
            <div
              className="md:w-[270px] w-full"
              data-uk-dropdown="pos: bottom-left; offset:10; animation: uk-animation-slide-bottom-small"
            >
              <nav className="cursor-pointer">
                <Link to="https://help.playsum.live/" target={'_blank'}>
                  <IonIcon
                    icon={helpCircleOutline}
                    className="text-2xl shrink-0 -ml-1"
                  />
                  Help & Support
                </Link>
                <hr />
                <a
                  data-uk-toggle="target: #delete-account"
                  className="text-red-400"
                >
                  <IonIcon
                    icon={stopCircleOutline}
                    className="text-2xl shrink-0 -ml-1"
                  />
                  Delete Account
                </a>
              </nav>
            </div>

            <DeleteAccountModal />
          </div>
        )}
      </div>

      <div
        className="relative border-b"
        tabIndex={-1}
        data-uk-slider="finite: true"
      >
        <nav className="uk-slider-container overflow-hidden nav__underline p-0 -mb-px">
          <ul className="uk-slider-items w-[calc(100%+10px)] !overflow-hidden">
            {menuItems.map((menuItem, idx) => {
              if (menuItem.id === activeTab) {
                return (
                  <li
                    key={idx}
                    className={`w-auto pr-2.5 ${menuItem.id === activeTab && 'border-b-[3px]'}`}
                  >
                    <Link to={menuItem.url}>{menuItem.name}</Link>
                  </li>
                );
              }
            })}
          </ul>
        </nav>

        <a
          className="absolute -translate-y-1/2 top-1/2 left-0 flex items-center w-20 h-full p-2 py-1 justify-start bg-gradient-to-r from-white via-white dark:from-[#101725] dark:via-[#101725]"
          href="#"
          data-uk-slider-item="previous"
        >
          <IonIcon icon={chevronBack} className="text-2xl ml-1" />
        </a>
        <a
          className="absolute right-0 -translate-y-1/2 top-1/2 flex items-center w-20 h-full p-2 py-1 justify-end bg-gradient-to-l from-white via-white dark:from-[#101725] dark:via-[#101725]"
          href="#"
          data-uk-slider-item="next"
        >
          <IonIcon icon={chevronForward} className="text-2xl mr-1" />
        </a>
      </div>
    </>
  );
};

export default AccountMenu;
