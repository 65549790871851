import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { useInfiniteQuery, useQuery } from 'react-query';
import { UserService } from '../services/UserService';
import ErrorToast from '../components/ErrorToast';
import { useTranslation } from 'react-i18next';
import Loader from '../components/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IUser } from '../interfaces/user.interface';
import AddFriendRequestModal from '../components/AddFriendRequestModal';
import { Link } from 'react-router-dom';
import { PAGINATION_DEFAULT } from '../constants';
import Avatar from '../components/Avatar';

const DiscoverUsers = () => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState('');
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [hasNoGames, setHasNoGames] = useState(false);

  const getProfileQuery = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      return await UserService.getProfile();
    },
    onSuccess: async (profile) => {
      if (profile?.games?.length === 0) {
        setHasNoGames(true);
      }
    },
    onError: async () => {
      ErrorToast(t('Errors.general'));
    },
  });

  const getUsersQuery = useInfiniteQuery(
    ['users', filter],
    // @ts-ignore
    UserService.searchUsers,
    {
      getNextPageParam: (result) =>
        result?.users.length === PAGINATION_DEFAULT
          ? result.offset + PAGINATION_DEFAULT
          : undefined,
      onError: async () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  useEffect(() => {
    if (hasNoGames) {
      // @ts-ignore
      if (typeof window?.UIkit !== 'undefined') {
        // @ts-ignore
        UIkit.modal('#no-games').show();
      }
    }
  }, [hasNoGames]);

  return (
    <Layout>
      <div className="max-w-[1065px] mx-auto">
        <div className="sm:my-6 my-3 flex items-center justify-between lg:mt-10 gap-4">
          <div>
            <h2 className="md:text-lg text-base font-semibold text-black">
              Discover Users
            </h2>
            <p className="font-normal text-sm text-gray-500 leading-6">
              Find gamers with similar interests.
            </p>
          </div>

          <div className="flex items-center gap-3 my-auto">
            <p className="font-normal text-sm">Filter by</p>
            <select
              className="w-36 dark:bg-slate-800"
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="">All Users</option>
              <option value="games">My Games</option>
              <option value="platforms">My Platforms</option>
            </select>
          </div>
        </div>

        {(getUsersQuery.isLoading || getProfileQuery.isLoading) && <Loader />}

        {getUsersQuery?.data && getProfileQuery.data?.games.length !== 0 && (
          <>
            <InfiniteScroll
              dataLength={
                getUsersQuery?.data
                  ? getUsersQuery?.data?.pages?.flatMap((page) => page.users)
                      .length
                  : 0
              }
              next={getUsersQuery.fetchNextPage}
              hasMore={getUsersQuery.hasNextPage || false} // Replace with a condition based on your data source
              loader={null}
            >
              <div className="grid md:grid-cols-2 gap-4 mb-5">
                {getUsersQuery?.data?.pages.map((group, i) => (
                  <React.Fragment key={i}>
                    {group.users.map((user, idx) => (
                      <div
                        key={idx}
                        className="flex md:items-center space-x-4 p-4 shadow-md dark:shadow-slate-950 rounded-lg"
                      >
                        <Link
                          to={`/user/${user.id}`}
                          className="sm:w-20 w-14 sm:h-20 h-14 flex-shrink-0 rounded-lg relative"
                        >
                          <Avatar
                            userId={user?.id as string}
                            username={user?.username}
                          />
                        </Link>
                        <Link to={`/user/${user.id}`} className="flex-1">
                          <div className="md:text-lg text-base font-semibold capitalize text-black dark:text-white">
                            {user.username}
                          </div>

                          <div className="text-sm text-gray-500 line-clamp-2">
                            {user?.games.map((game) => game.title).join(', ')}
                          </div>
                        </Link>
                        <button
                          type="button"
                          className="button border dark:border-gray-600/60 dark:text-slate-50 text-slate-800 my-auto"
                          data-uk-toggle="target: #add-friend-request"
                          onClick={() => setSelectedUser(user)}
                        >
                          <i className="la la-user-plus md:text-lg"></i>
                        </button>
                      </div>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            </InfiniteScroll>

            <AddFriendRequestModal user={selectedUser} />
          </>
        )}
      </div>

      <div id="no-games-modal-wrapper">
        <div
          className="hidden lg:p-20 uk- open"
          id="no-games"
          data-uk-modal="container: #no-games-modal-wrapper;  bgClose: false; escClose: false"
        >
          <div className="uk-modal-dialog tt relative overflow-hidden mx-auto bg-white shadow-xl rounded-lg md:w-[520px] w-full dark:bg-dark2 text-black dark:text-white">
            <div className="text-center py-4 border-b mb-0 dark:border-slate-700">
              <div className="text-base font-semibold text-black dark:text-white px-10">
                Finish Profile Setup
              </div>
            </div>
            <div className="p-5 text-sm">
              <div className="mb-4 last:mb-0">
                <div className="text-sm text-center mb-3">
                  {"You didn't add any games yet."}
                </div>
                <div className="text-sm text-center">
                  In order to discover and connect with other members you need
                  to add games to your{' '}
                  <Link to="/game-library" className="text-[#db00ff]">
                    game library
                  </Link>
                  .
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DiscoverUsers;
