import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { UserService } from '../services/UserService';
import * as yup from 'yup';
import { Formik } from 'formik';
import Loader from '../components/Loader';
import ErrorToast from '../components/ErrorToast';
import PublicLayout from '../components/PublicLayout';
import ButtonPrimary from '../components/Button/ButtonPrimary';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';

const ResetPassword = () => {
  const { search } = useLocation();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = React.useState(false);

  const resetPasswordMutation = useMutation(
    ({ token, password }: { token: string; password: string }) => {
      return UserService.resetPassword(token, password);
    },
    {
      onSuccess: async () => {
        if (searchParams.has('token')) {
          searchParams.delete('token');
          setSearchParams(searchParams);
        }
      },
      onError: () => {
        ErrorToast(t('Errors.passwordResetFailed'));
      },
    },
  );

  useEffect(() => {
    if (!searchParams.has('token')) {
      window.location.replace('/login');
    }
  }, []);

  return (
    <PublicLayout>
      <>
        {resetPasswordMutation.isLoading && <Loader />}

        {resetPasswordMutation.isSuccess ? (
          <div>
            <h2 className="text-2xl font-semibold mb-5">
              {t('ResetPassword.success.headline')}
            </h2>

            <p className="text-sm font-normal">
              {t('ResetPassword.success.subheadline')}
            </p>

            <div className="mt-7">
              <ButtonPrimary href={`/login${search}`}>
                {t('ResetPassword.success.goToLoginAction')}
              </ButtonPrimary>
            </div>
          </div>
        ) : (
          <>
            <div>
              <h2 className="text-2xl font-semibold mb-1.5">
                {t('ResetPassword.headline')}
              </h2>

              <p className="text-sm font-normal">
                {t('ResetPassword.subheadline')}
              </p>
            </div>

            <Formik
              enableReinitialize={true}
              initialValues={{
                password: '',
                passwordRepeat: '',
              }}
              validationSchema={yup.object({
                password: yup
                  .string()
                  .trim()
                  .min(6, t('FormValidations.passwordLength'))
                  .max(64, t('FormValidations.passwordLength'))
                  .matches(/^(\S+$)/g, t('FormValidations.whitespaces'))
                  .required(t('FormValidations.passwordRequired')),
                passwordRepeat: yup
                  .string()
                  .trim()
                  .required(t('FormValidations.passwordRequired'))
                  .min(6, t('FormValidations.passwordLength'))
                  .max(64, t('FormValidations.passwordLength'))
                  .matches(/^(\S+$)/g, t('FormValidations.whitespaces'))
                  .oneOf(
                    [yup.ref('password')],
                    t('FormValidations.passwordsNoMatch'),
                  ),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                resetPasswordMutation.mutate({
                  token: searchParams.get('token') as string,
                  password: values.password,
                });

                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form
                  className="space-y-7 text-sm text-black font-medium dark:text-white"
                  onSubmit={handleSubmit}
                >
                  <div>
                    <label htmlFor="email" className="">
                      {t('FormLabels.password')}
                    </label>
                    <div className="mt-2.5">
                      <div className="flex">
                        <input
                          id="password"
                          type={showPassword ? 'text' : 'password'}
                          className="w-full"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span
                          className="flex justify-around items-center"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <IonIcon
                            icon={showPassword ? eyeOutline : eyeOffOutline}
                            className="absolute mr-10 cursor-pointer text-lg"
                          />
                        </span>
                      </div>
                      {errors.password && (
                        <p className="text-red-500 text-xs italic mt-1">
                          {errors.password}
                        </p>
                      )}
                    </div>
                  </div>

                  <div>
                    <label htmlFor="passwordRepeat" className="">
                      {t('FormLabels.repeatPassword')}{' '}
                    </label>
                    <div className="mt-2.5">
                      <div className="flex">
                        <input
                          id="passwordRepeat"
                          type={showPasswordRepeat ? 'text' : 'password'}
                          className="w-full"
                          value={values.passwordRepeat}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span
                          className="flex justify-around items-center"
                          onClick={() =>
                            setShowPasswordRepeat(!showPasswordRepeat)
                          }
                        >
                          <IonIcon
                            icon={
                              showPasswordRepeat ? eyeOutline : eyeOffOutline
                            }
                            className="absolute mr-10 cursor-pointer text-lg"
                          />
                        </span>
                      </div>
                      {errors.passwordRepeat && (
                        <p className="text-red-500 text-xs italic mt-1">
                          {errors.passwordRepeat}
                        </p>
                      )}
                    </div>
                  </div>

                  <div>
                    <ButtonPrimary disabled={isSubmitting}>
                      {t('ResetPassword.resetPasswordAction')}
                    </ButtonPrimary>
                  </div>
                </form>
              )}
            </Formik>
          </>
        )}
      </>
    </PublicLayout>
  );
};

export default ResetPassword;
