import React from 'react';
import Layout from '../components/Layout';
import {
  Chat as GetstreamChat,
  Channel,
  ChannelList,
  Window,
  MessageList,
  MessageInput,
} from 'stream-chat-react';
import { useAuthStore } from '../store/zustand';
import Loader from '../components/Loader';
import { useThemeMode } from '../hooks/useThemeMode';
import { Link } from 'react-router-dom';
import { useChatClient } from '../hooks/useChatClient';
import DirectMessageChannelHeader from '../components/DirectMessageChannelHeader';
import CustomDateSeparator from '../components/CustomDateSeparator';
import CustomMessageAvatar from '../components/CustomMessageAvatar';
import { IonIcon } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import Avatar from '../components/Avatar';

const Chats = () => {
  const user = useAuthStore((state) => state.user);
  const { isDarkMode } = useThemeMode();

  const client = useChatClient();

  if (!client || !user) return <Loader />;

  const filters = {
    members: { $in: [user.id] },
    type: 'one-to-one',
  };
  const options = { presence: true, state: true, watch: true };

  return (
    <Layout classes="2xl:ml-[--w-side] xl:ml-[--w-side-sm] p-2.5 h-[calc(100dvh-var(--m-top))] mt-[--m-top]">
      <div className="relative overflow-hidden -m-2.5 dark:border-slate-800">
        <div className="flex bg-white dark:bg-bgbody">
          <GetstreamChat
            client={client}
            theme={
              isDarkMode ? 'str-chat__theme-dark' : 'str-chat__theme-light'
            }
          >
            <div className="md:w-[360px] relative border-r dark:border-slate-800">
              <div
                id="side-chat"
                className="top-0 left-0 max-md:fixed max-md:w-5/6 max-md:h-screen bg-white z-50 max-md:shadow max-md:-translate-x-full dark:bg-bgbody border-r dark:border-slate-800"
              >
                <div className="p-4 pb-3 mt-[--m-top] md:hidden border-b dark:border-slate-800">
                  <div className="flex items-center justify-between">
                    <h2 className="text-lg font-bold text-black ml-1 dark:text-white">
                      Chats
                    </h2>
                  </div>
                </div>

                <div className="p-4 pb-3 border-b dark:border-slate-800 hidden md:block">
                  <div className="flex mt-2 items-center justify-between">
                    <h2 className="text-2xl font-bold text-black ml-1 dark:text-white">
                      Chats
                    </h2>

                    <div className="flex items-center gap-2.5">
                      <button
                        type="button"
                        className="md:hidden"
                        data-uk-toggle="target: #side-chat ; cls: max-md:-translate-x-full"
                      >
                        <IonIcon icon={chevronBackOutline} />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="space-y-2 p-2 overflow-y-auto md:h-[calc(100dvh-204px)] h-[calc(100dvh-80px)]">
                  <ChannelList
                    filters={filters}
                    options={options}
                    Preview={({ channel, activeChannel, setActiveChannel }) => {
                      const isSelected = channel.id === activeChannel?.id;

                      const otherUserIds = Object.keys(
                        channel.state.members,
                      ).filter((key) => key !== client?.user?.id);

                      const otherUser =
                        channel.state.members[otherUserIds[0]]?.user;

                      return (
                        <div className="relative flex items-center gap-4 p-2 duration-200 rounded-lg hover:bg-secondery dark:hover:bg-white/10">
                          <Link
                            to={`/user/${otherUser?.id}`}
                            className="relative w-10 h-10 shrink-0"
                          >
                            <Avatar
                              userId={otherUser?.id as string}
                              username={otherUser?.name}
                            />

                            {otherUser?.online && (
                              <div className="w-3 h-3 absolute bottom-0 right-0 bg-green-500 rounded-full border dark:border-slate-800"></div>
                            )}
                          </Link>
                          <div
                            className="flex-1 min-w-0 cursor-pointer"
                            onClick={() =>
                              !isSelected && setActiveChannel?.(channel)
                            }
                          >
                            <div className="flex items-center gap-2 mb-1">
                              <div className="mr-auto text-sm text-black dark:text-white font-medium">
                                {otherUser?.name}
                              </div>
                              <div className="text-xs text-gray-500 dark:text-white/80">
                                {channel.lastMessage()?.updated_at.getDate() ===
                                new Date().getDate()
                                  ? channel
                                      .lastMessage()
                                      ?.updated_at.toLocaleTimeString()
                                  : channel
                                      .lastMessage()
                                      ?.updated_at.toLocaleDateString()}
                              </div>
                              {!channel.muteStatus().muted &&
                                channel.countUnread() > 0 && (
                                  <div className="w-2.5 h-2.5 bg-[#db00ff] rounded-full"></div>
                                )}
                            </div>
                            <div className="font-normal overflow-hidden text-ellipsis text-xs whitespace-nowrap">
                              {channel.lastMessage()?.text}
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>

              <div
                id="side-chat"
                className="bg-slate-100/40 backdrop-blur w-full h-full dark:bg-slate-800/40 z-40 fixed inset-0 max-md:-translate-x-full md:hidden"
                data-uk-toggle="target: #side-chat ; cls: max-md:-translate-x-full"
              ></div>
            </div>

            <div className="flex-1">
              <Channel
                maxNumberOfFiles={0}
                DateSeparator={CustomDateSeparator}
                Avatar={CustomMessageAvatar}
              >
                <Window>
                  <DirectMessageChannelHeader client={client} />

                  <div className="w-full overflow-y-auto md:h-[calc(100dvh-204px)] h-[calc(100dvh-195px)]">
                    <MessageList />
                  </div>
                  <div className="flex items-center md:gap-4 gap-2 md:p-3 p-2">
                    <MessageInput />
                  </div>
                </Window>
              </Channel>
            </div>
          </GetstreamChat>
        </div>
      </div>
    </Layout>
  );
};

export default Chats;
