import { RingLoader } from 'react-spinners';

const Loader = () => {
  return (
    <>
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-[2000] overflow-hidden bg-black bg-opacity-60 flex flex-col items-center justify-center">
        <div className="flex items-center">
          <RingLoader color={'#db00ff'} size={70} speedMultiplier={0.8} />
        </div>
      </div>
    </>
  );
};

export default Loader;
