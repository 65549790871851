import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { createAuthSlice, IAuthSlice } from './authSlice';
import { cookieStorage } from './storage';

export const useAuthStore = create<IAuthSlice>()(
  persist(
    (...a) => ({
      ...createAuthSlice(...a),
    }),
    {
      name: 'plysm-auth',
      storage: createJSONStorage(() => cookieStorage),
    },
  ),
);
