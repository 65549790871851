import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { FriendshipService } from '../services/FriendshipService';
import ErrorToast from './ErrorToast';
import { IUser } from '../interfaces/user.interface';
import Loader from './Loader';

const AddFriendRequestModal = ({ user }: { user?: IUser }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const addFriendRequestMutation = useMutation(
    (userId: string) => {
      return FriendshipService.requestFriendRequest(userId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['users']);
      },
      onError: (e: any) => {
        if (e?.response?.status === 409) {
          ErrorToast(t('Errors.friendshipAlreadyRequested'));
        } else {
          ErrorToast(t('Errors.general'));
        }
      },
    },
  );

  return (
    <div id="add-friend-request-modal-wrapper">
      {addFriendRequestMutation.isLoading && <Loader />}

      <div
        className="hidden lg:p-20 uk- open"
        id="add-friend-request"
        data-uk-modal="container: #add-friend-request-modal-wrapper;"
      >
        {user && (
          <div className="uk-modal-dialog tt relative overflow-hidden mx-auto bg-white shadow-xl rounded-lg md:w-[520px] w-full dark:bg-dark2 text-black dark:text-white">
            <div className="text-center py-4 border-b mb-0 dark:border-slate-700">
              <div className="text-base font-semibold text-black dark:text-white px-10">
                Friend Request
              </div>
              <button
                type="button"
                className="button-icon absolute top-0 right-0 m-2.5 uk-modal-close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <>
              <div className="p-5 text-sm">
                Do you want to send a friends request to <i>{user?.username}</i>
              </div>
              <div className="px-5 pb-5 flex justify-between items-center">
                <div className="flex items-center gap-3">
                  <button
                    type="button"
                    className="button py-2 px-12 text-[14px] bg-slate-900 dark:bg-slate-100 text-slate-50 dark:text-slate-800 uk-modal-close"
                    onClick={() => {
                      void addFriendRequestMutation.mutate(user.id);
                    }}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="button py-2 px-12 text-[14px] border dark:border-gray-600/60 dark:text-slate-50 text-slate-800 uk-modal-close"
                  >
                    No
                  </button>
                </div>
              </div>
            </>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddFriendRequestModal;
