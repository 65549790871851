import React, { useState } from 'react';
import Layout from '../components/Layout';
import { useTranslation } from 'react-i18next';
import { IProfile } from '../interfaces/profile.interface';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UserService } from '../services/UserService';
import ErrorToast from '../components/ErrorToast';
import Loader from '../components/Loader';
import AccountMenu from '../components/AccountMenu';
import { Platform } from '../enums/platform.enum';
import { useDebounce } from '@react-hooks-hub/use-debounce';
import { GameService } from '../services/GameService';
import { IDiscoveredGame } from '../interfaces/discovered-game.interface';
import INoPackshot from '../assets/images/no-packshot.png';
import { MAX_GAMES } from '../constants';

const GameLibrary = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [profile, setProfile] = useState<IProfile>();
  const [gamesToAdd, setGamesToAdd] = useState<
    { gameId: string; platforms: Platform[] }[]
  >([]);

  const getProfileQuery = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      return await UserService.getProfile();
    },
    onSuccess: async (profile) => {
      setProfile(profile);
      setGamesToAdd(
        profile.games.map((game) => {
          return {
            gameId: game.gameId,
            platforms: game.platforms,
          };
        }),
      );
    },
    onError: async () => {
      ErrorToast(t('Errors.general'));
    },
  });

  const updateProfileMutation = useMutation(
    (updateData: { games?: { gameId: string; platforms: Platform[] }[] }) => {
      return UserService.updateProfile(updateData);
    },
    {
      onSuccess: async (profile) => {
        setProfile(profile);
        setGamesToAdd(
          profile.games.map((game) => {
            return {
              gameId: game.gameId,
              platforms: game.platforms,
            };
          }),
        );

        queryClient.invalidateQueries(['profile']);
      },
      onError: () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  const searchGamesMutation = useMutation(
    (title: string) => {
      return GameService.search(title);
    },
    {
      onError: () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const addGame = (gameId: string, platforms: Platform[]) => {
    const games = [
      ...new Map(
        [...gamesToAdd, { gameId: gameId, platforms: platforms }].map((v) => [
          v.gameId,
          v,
        ]),
      ).values(),
    ];

    if (games.length > MAX_GAMES) {
      ErrorToast(t('FormValidations.games'));
    } else {
      setGamesToAdd(games);

      updateProfileMutation.mutate({
        games: games,
      });
    }
  };

  const removeGame = (gameId: string, platform?: Platform) => {
    let games = gamesToAdd.filter((game) => game.gameId !== gameId);

    if (platform) {
      games = gamesToAdd;

      games.map((gameToAdd, idx) => {
        if (gameToAdd.gameId === gameId) {
          games[idx].platforms = games[idx].platforms.filter(
            (gameToAddPlatform) => gameToAddPlatform !== platform,
          );

          if (games[idx].platforms?.length === 0) {
            delete games[idx];
          }
        }
      });
    }

    games = games.filter((game) => Object.keys(game).length !== 0);

    setGamesToAdd(games);

    updateProfileMutation.mutate({
      games: games,
    });
  };

  const debouncedGameSearch = useDebounce(searchGamesMutation.mutate, 300);

  const renderPlatformOption = (
    discoveredGame: IDiscoveredGame,
    platform: Platform,
  ) => {
    const existingGame = gamesToAdd.find(
      (game) => game?.gameId === discoveredGame.id,
    );

    if (existingGame) {
      if (existingGame.platforms.includes(platform)) {
        return (
          <option key={platform} value={platform}>
            {/*@ts-ignore*/}
            &#10003; {t(`Platforms.${platform}`)}
          </option>
        );
      }
    }

    return (
      <option key={platform} value={platform}>
        {/*@ts-ignore*/}
        {t(`Platforms.${platform}`)}
      </option>
    );
  };

  return (
    <Layout>
      <>
        {(getProfileQuery.isLoading ||
          updateProfileMutation.isLoading ||
          searchGamesMutation.isLoading) && <Loader />}

        {profile && (
          <div className="max-w-[1065px] mx-auto">
            <div className="relative">
              <AccountMenu user={profile} activeTab="gameLibrary" />

              <div className="md:py-12 py-6 overflow-hidden text-black dark:text-white text-sm">
                <div className="grid lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-4 grid-cols-2 gap-4">
                  <a
                    className="shadow-md dark:shadow-slate-950 rounded-lg"
                    href="#"
                  >
                    <div
                      className={`${profile.games.length > 0 ? 'h-full' : 'h-48'}`}
                    >
                      <div
                        className={`${profile.games.length > 0 ? 'h-full' : 'h-48'} bg-bgbody`}
                        data-uk-toggle="target: #game-search"
                      >
                        <div className="flex items-center justify-center h-full">
                          <div className="flex flex-col">
                            <i
                              className={`la la-plus-circle text-5xl text-center mb-3`}
                            />
                            Add / Edit Game
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>

                  {profile.games.map((game, idx) => {
                    return (
                      <div key={idx}>
                        <div className="shadow-md dark:shadow-slate-950 rounded-lg">
                          <div>
                            <div className="aspect-auto bg-bgbody">
                              <img
                                className="object-cover"
                                src={`${game?.imageId ? 'https://images.igdb.com/igdb/image/upload/t_cover_big/' + game.imageId + '.jpg' : INoPackshot}`}
                                alt={`${game.title} Cover`}
                              />
                            </div>
                          </div>
                          <div className="card-body">
                            <div>
                              <h4 className="card-title text-sm line-clamp-1">
                                {game.title}
                              </h4>
                              <p className="text-neutral-500 dark:text-neutral-400 line-clamp-1">
                                {game.platforms
                                  .map((platform) =>
                                    // @ts-ignore
                                    t(`Platforms.${platform}`),
                                  )
                                  .join(', ')}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          className="uk-drop w-52 max-md:!hidden"
                          data-uk-drop="pos: center; animation: uk-animation-scale-up"
                        >
                          <div className="shadow-md dark:shadow-slate-950 rounded-lg">
                            <div className="aspect-auto bg-bgbody">
                              <img
                                className="object-cover"
                                src={`${game?.imageId ? 'https://images.igdb.com/igdb/image/upload/t_cover_big/' + game.imageId + '.jpg' : INoPackshot}`}
                                alt={`${game.title} Cover`}
                              />
                              <div className="card-overly"></div>
                            </div>
                            <div
                              className="card-body"
                              data-uk-scrollspy="target: > * ; cls: uk-animation-slide-bottom-small ; delay: 60 ;repeat: true"
                            >
                              <h4 className="card-title text-sm font-semibold">
                                {game.title}
                              </h4>
                              <p className="text-neutral-500 dark:text-neutral-400">
                                {game.platforms
                                  .map((platform) =>
                                    // @ts-ignore
                                    t(`Platforms.${platform}`),
                                  )
                                  .join(', ')}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div id="game-search-modal-wrapper">
                  <div
                    className="hidden lg:p-20 uk- open"
                    id="game-search"
                    data-uk-modal="container: #reveal-key-modal-wrapper;"
                  >
                    <div className="uk-modal-dialog tt relative overflow-hidden mx-auto shadow-xl rounded-lg bg-bgbody dark:bg-slate-800 md:w-[720px] w-full">
                      <div className="text-center py-4 border-b mb-0 dark:border-slate-700">
                        <div className="text-base font-semibold text-black dark:text-white px-10">
                          Game search
                        </div>
                        <button
                          type="button"
                          className="button-icon absolute top-0 right-0 m-2.5 uk-modal-close"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>

                      <div className="p-5">
                        <div
                          id="search--box"
                          className="sm:relative rounded-md overflow-hidden z-20 left-0"
                        >
                          <input
                            type="text"
                            placeholder="Search games"
                            className="w-full !font-normal !bg-transparent h-12 !text-sm"
                            onChange={(e) =>
                              debouncedGameSearch(e.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className="px-5 pb-5 overflow-auto">
                        <div className="grid md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4">
                          {searchGamesMutation?.data?.map(
                            (discoveredGame, idx) => {
                              return (
                                <div key={idx}>
                                  <div className="shadow-md dark:shadow-slate-950 rounded-lg">
                                    <div>
                                      <div className="aspect-auto bg-bgbody">
                                        <img
                                          className="object-cover"
                                          src={`${discoveredGame?.imageId ? 'https://images.igdb.com/igdb/image/upload/t_cover_big/' + discoveredGame.imageId + '.jpg' : INoPackshot}`}
                                          alt={`${discoveredGame.title} Cover`}
                                        />
                                      </div>
                                    </div>
                                    <div className="card-body">
                                      <h4 className="card-title text-sm line-clamp-1">
                                        {discoveredGame.title}
                                      </h4>
                                      <p className="text-neutral-500 dark:text-neutral-400 line-clamp-1">
                                        {discoveredGame.platforms
                                          .map((platform) =>
                                            // @ts-ignore
                                            t(`Platforms.${platform}`),
                                          )
                                          .join(', ')}
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    className="uk-drop w-52 max-md:!hidden"
                                    data-uk-drop="pos: center; animation: uk-animation-scale-up"
                                  >
                                    <div className="shadow-md dark:shadow-slate-950 dark:bg-slate-800 rounded-lg">
                                      <div className="aspect-auto bg-bgbody">
                                        <img
                                          className="object-cover"
                                          src={`${discoveredGame?.imageId ? 'https://images.igdb.com/igdb/image/upload/t_cover_big/' + discoveredGame.imageId + '.jpg' : INoPackshot}`}
                                          alt={`${discoveredGame.title} Cover`}
                                        />
                                        <div className="card-overly"></div>
                                      </div>
                                      <div
                                        className="card-body"
                                        data-uk-scrollspy="target: > * ; cls: uk-animation-slide-bottom-small ; delay: 60 ;repeat: true"
                                      >
                                        <h4 className="card-title text-sm font-semibold">
                                          {discoveredGame.title}
                                        </h4>
                                        <div
                                          className="flex gap-2 mt-2"
                                          data-uk-scrollspy-class="uk-animation-slide-top-small"
                                        >
                                          <select
                                            id="platforms"
                                            className="w-full dark:bg-slate-800"
                                            onChange={(e) => {
                                              if (
                                                e.target.value === 'invalid'
                                              ) {
                                                return;
                                              }

                                              const existingGame =
                                                gamesToAdd.find(
                                                  (game) =>
                                                    game.gameId ===
                                                    discoveredGame.id,
                                                );

                                              if (existingGame) {
                                                if (
                                                  existingGame.platforms.includes(
                                                    e.target.value as Platform,
                                                  )
                                                ) {
                                                  removeGame(
                                                    discoveredGame.id,
                                                    e.target.value as Platform,
                                                  );
                                                } else {
                                                  addGame(discoveredGame.id, [
                                                    ...existingGame.platforms,
                                                    e.target.value as Platform,
                                                  ]);
                                                }
                                              } else {
                                                addGame(discoveredGame.id, [
                                                  e.target.value as Platform,
                                                ]);
                                              }
                                            }}
                                          >
                                            <option value="invalid">
                                              Select Platforms
                                            </option>
                                            {discoveredGame.platforms.map(
                                              (platform) => {
                                                return renderPlatformOption(
                                                  discoveredGame,
                                                  platform,
                                                );
                                              },
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            },
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </Layout>
  );
};

export default GameLibrary;
