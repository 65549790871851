import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../assets/images/logo.png';
import ThemeSwitch from './ThemeSwitch';
import Onboarding1 from '../assets/images/onboarding-1.png';
import Onboarding2 from '../assets/images/onboarding-2.png';
import Onboarding3 from '../assets/images/onboarding-3.png';

const PublicLayout = ({
  children,
}: {
  children: string | JSX.Element | JSX.Element[];
}) => {
  const { search } = useLocation();

  return (
    <>
      <div className="sm:flex">
        <div className="relative lg:w-[580px] md:w-96 w-full p-10 min-h-screen shadow-xl flex items-center pt-10 z-10">
          <div className="w-full lg:max-w-sm mx-auto space-y-10">
            <Link to={`/login${search}`} className="absolute top-10">
              <img
                src={Logo}
                className="h-8 sm:h-10 w-auto"
                alt="Playsum Logo"
              />
            </Link>

            {children}
          </div>
        </div>

        <div className="flex-1 relative max-md:hidden">
          <div
            className="relative w-full h-full"
            tabIndex={-1}
            data-uk-slideshow="animation: slide; autoplay: true"
          >
            <ul className="uk-slideshow-items w-full h-full">
              <li className="w-full">
                <img
                  src={Onboarding1}
                  alt="Onboarding 1"
                  className="w-full h-full object-cover"
                />
                <div className="w-full h-96 bg-gradient-to-t from-black absolute bottom-0 left-0"></div>
              </li>
              <li className="w-full">
                <img
                  src={Onboarding2}
                  alt="Onboarding 2"
                  className="w-full h-full object-cover"
                />
                <div className="w-full h-96 bg-gradient-to-t from-black absolute bottom-0 left-0"></div>
              </li>
              <li className="w-full">
                <img
                  src={Onboarding3}
                  alt="Onboarding 3"
                  className="w-full h-full object-cover"
                />
                <div className="w-full h-96 bg-gradient-to-t from-black absolute bottom-0 left-0"></div>
              </li>
            </ul>

            <div className="flex justify-center">
              <ul className="inline-flex flex-wrap justify-center absolute bottom-8 gap-1.5 uk-dotnav uk-slideshow-nav">
                {' '}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <ThemeSwitch />
    </>
  );
};

export default PublicLayout;
