import { useEffect } from 'react';
import { createGlobalState } from 'react-hooks-global-state';
import Cookies from 'js-cookie';

const initialState = { isDarkmode: false };
const { useGlobalState } = createGlobalState(initialState);

export const useThemeMode = () => {
  const [isDarkMode, setIsDarkMode] = useGlobalState('isDarkmode');

  useEffect(() => {
    if (
      Cookies.get('theme') === 'dark' ||
      (!Cookies.get('theme') &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      toDark();
    } else {
      toLight();
    }
  }, []);

  const toDark = () => {
    setIsDarkMode(true);
    const root = document.querySelector('html');
    if (!root) return;
    root.classList.remove('light');
    !root.classList.contains('dark') && root.classList.add('dark');
    Cookies.set('theme', 'dark', {
      expires: 365,
      sameSite: 'strict',
      secure: process.env.NODE_ENV !== 'development',
      domain:
        process.env.NODE_ENV === 'production'
          ? window.location.hostname.replace('app', '')
          : 'localhost',
    });
  };

  const toLight = () => {
    setIsDarkMode(false);
    const root = document.querySelector('html');
    if (!root) return;
    root.classList.remove('dark');
    root.classList.add('light');
    Cookies.set('theme', 'light', {
      expires: 365,
      sameSite: 'strict',
      secure: process.env.NODE_ENV !== 'development',
      domain:
        process.env.NODE_ENV === 'production'
          ? window.location.hostname.replace('app', '')
          : 'localhost',
    });
  };

  function _toogleDarkMode() {
    if (Cookies.get('theme') === 'light') {
      toDark();
    } else {
      toLight();
    }
  }

  return {
    isDarkMode,
    toDark,
    toLight,
    _toogleDarkMode,
  };
};
