import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { UserService } from '../services/UserService';
import Loader from '../components/Loader';
import PublicLayout from '../components/PublicLayout';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import ButtonPrimary from '../components/Button/ButtonPrimary';

const AccountVerification = () => {
  const { search } = useLocation();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const accountVerificationMutation = useMutation(
    ({ token }: { token: string }) => {
      return UserService.verifyAccount(token);
    },
    {
      onSuccess: async () => {
        if (searchParams.has('token')) {
          searchParams.delete('token');
          setSearchParams(searchParams);
        }
      },
    },
  );

  useEffect(() => {
    if (searchParams.has('token')) {
      accountVerificationMutation.mutate({
        token: searchParams.get('token') as string,
      });
    } else {
      window.location.replace('/login');
    }
  }, []);

  return (
    <PublicLayout>
      <>
        {accountVerificationMutation.isLoading && <Loader />}

        {accountVerificationMutation.isSuccess && (
          <div>
            <h2 className="text-2xl font-semibold mb-5">
              {t('AccountVerification.success.headline')}
            </h2>

            <p className="text-sm font-normal">
              {t('AccountVerification.success.subheadline')}
            </p>

            <div className="mt-7">
              <ButtonPrimary href={`/login${search}`}>
                {t('AccountVerification.success.goToLoginAction')}
              </ButtonPrimary>
            </div>
          </div>
        )}

        {accountVerificationMutation.isError && (
          <div>
            <h2 className="text-2xl font-semibold mb-5">
              {t('AccountVerification.failed.headline')}
            </h2>

            <p className="text-sm font-normal">
              <Trans
                i18nKey="Errors.accountVerificationFailed"
                components={{
                  anchor: (
                    <Link
                      to={`/resend-verification${search}`}
                      className="text-[#db00ff]"
                    />
                  ),
                }}
              />
            </p>
          </div>
        )}
      </>
    </PublicLayout>
  );
};

export default AccountVerification;
