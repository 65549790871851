export enum LanguageCode {
  AA = 'AA',
  AB = 'AB',
  AE = 'AE',
  AF = 'AF',
  AK = 'AK',
  AM = 'AM',
  AN = 'AN',
  AR = 'AR',
  AS = 'AS',
  AV = 'AV',
  AY = 'AY',
  AZ = 'AZ',
  BA = 'BA',
  BE = 'BE',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BR = 'BR',
  BS = 'BS',
  CA = 'CA',
  CE = 'CE',
  CH = 'CH',
  CO = 'CO',
  CR = 'CR',
  CS = 'CS',
  CU = 'CU',
  CV = 'CV',
  CY = 'CY',
  DA = 'DA',
  DE = 'DE',
  DV = 'DV',
  DZ = 'DZ',
  EE = 'EE',
  EL = 'EL',
  EN = 'EN',
  EO = 'EO',
  ES = 'ES',
  ET = 'ET',
  EU = 'EU',
  FA = 'FA',
  FF = 'FF',
  FI = 'FI',
  FJ = 'FJ',
  FO = 'FO',
  FR = 'FR',
  FY = 'FY',
  GA = 'GA',
  GD = 'GD',
  GL = 'GL',
  GN = 'GN',
  GU = 'GU',
  GV = 'GV',
  HA = 'HA',
  HE = 'HE',
  HI = 'HI',
  HO = 'HO',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  HY = 'HY',
  HZ = 'HZ',
  IA = 'IA',
  ID = 'ID',
  IE = 'IE',
  IG = 'IG',
  II = 'II',
  IK = 'IK',
  IO = 'IO',
  IS = 'IS',
  IT = 'IT',
  IU = 'IU',
  JA = 'JA',
  JV = 'JV',
  KA = 'KA',
  KG = 'KG',
  KI = 'KI',
  KJ = 'KJ',
  KK = 'KK',
  KL = 'KL',
  KM = 'KM',
  KN = 'KN',
  KO = 'KO',
  KR = 'KR',
  KS = 'KS',
  KU = 'KU',
  KV = 'KV',
  KW = 'KW',
  KY = 'KY',
  LA = 'LA',
  LB = 'LB',
  LG = 'LG',
  LI = 'LI',
  LN = 'LN',
  LO = 'LO',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  MG = 'MG',
  MH = 'MH',
  MI = 'MI',
  MK = 'MK',
  ML = 'ML',
  MN = 'MN',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MY = 'MY',
  NA = 'NA',
  NB = 'NB',
  ND = 'ND',
  NE = 'NE',
  NG = 'NG',
  NL = 'NL',
  NN = 'NN',
  NO = 'NO',
  NR = 'NR',
  NV = 'NV',
  NY = 'NY',
  OC = 'OC',
  OJ = 'OJ',
  OM = 'OM',
  OR = 'OR',
  OS = 'OS',
  PA = 'PA',
  PI = 'PI',
  PL = 'PL',
  PS = 'PS',
  PT = 'PT',
  QU = 'QU',
  RM = 'RM',
  RN = 'RN',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SI = 'SI',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SQ = 'SQ',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SU = 'SU',
  SV = 'SV',
  SW = 'SW',
  TA = 'TA',
  TE = 'TE',
  TG = 'TG',
  TH = 'TH',
  TI = 'TI',
  TK = 'TK',
  TL = 'TL',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TS = 'TS',
  TT = 'TT',
  TW = 'TW',
  TY = 'TY',
  UG = 'UG',
  UK = 'UK',
  UR = 'UR',
  UZ = 'UZ',
  VE = 'VE',
  VI = 'VI',
  VO = 'VO',
  WA = 'WA',
  WO = 'WO',
  XH = 'XH',
  YI = 'YI',
  YO = 'YO',
  ZA = 'ZA',
  ZH = 'ZH',
  ZU = 'ZU',
}
