import { chevronBackOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import React from 'react';
import { useChannelStateContext } from 'stream-chat-react';
import { minidenticon } from 'minidenticons';

const WaypointChannelHeader = () => {
  const { channel } = useChannelStateContext();

  return (
    <>
      <div className="flex items-center justify-between gap-2 w- px-6 py-3.5 z-10 border-b dark:border-slate-800 uk-animation-slide-top-medium">
        <div className="flex items-center sm:gap-4 gap-2">
          <button
            type="button"
            className="md:hidden"
            data-uk-toggle="target: #side-chat ; cls: max-md:-translate-x-full"
          >
            <IonIcon icon={chevronBackOutline} className="text-2xl -ml-4" />
          </button>

          <div className="relative max-md:hidden">
            <img
              src={
                'data:image/svg+xml;utf8,' +
                encodeURIComponent(minidenticon(channel?.data?.name || ''))
              }
              alt={`${channel?.data?.name || ''} Avatar`}
              className="w-8 h-8 rounded-full shadow object-cover ring-1 ring-neutral-200 dark:ring-neutral-400 p-1"
            />
          </div>
          <div>
            <div className="text-base font-bold">
              {channel?.data?.name || ''}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WaypointChannelHeader;
