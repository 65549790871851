import React, { useState } from 'react';
import Layout from '../components/Layout';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import ErrorToast from '../components/ErrorToast';
import { useTranslation } from 'react-i18next';
import Loader from '../components/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { WaypointService } from '../services/WaypointService';
import { minidenticon } from 'minidenticons';
import { IWaypoint } from '../interfaces/waypoint.interface';
import { Link } from 'react-router-dom';
import { PAGINATION_DEFAULT } from '../constants';

const JoinedWaypoints = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [waypoint, setWaypoint] = useState<IWaypoint>();

  const getJoinedWaypointsQuery = useInfiniteQuery(
    ['joinedWaypoints'],
    // @ts-ignore
    WaypointService.getJoined,
    {
      getNextPageParam: (result) =>
        result?.waypoints.length === PAGINATION_DEFAULT
          ? result.offset + PAGINATION_DEFAULT
          : undefined,
      onError: async () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  const leaveWaypointMutation = useMutation(
    (waypointId: string) => {
      return WaypointService.leave(waypointId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['waypoints']);
        queryClient.invalidateQueries(['joinedWaypoints']);
      },
      onError: () => {
        ErrorToast(t('Errors.general'));
      },
    },
  );

  return (
    <Layout>
      <div className="max-w-[1065px] mx-auto">
        <div className="sm:my-6 my-3 flex items-center justify-between lg:mt-10 gap-4">
          <div>
            <h2 className="md:text-lg text-base font-semibold text-black">
              Joined Waypoints
            </h2>
          </div>
        </div>

        {getJoinedWaypointsQuery.isLoading && <Loader />}

        {getJoinedWaypointsQuery?.data && (
          <>
            <InfiniteScroll
              dataLength={
                getJoinedWaypointsQuery?.data
                  ? getJoinedWaypointsQuery?.data?.pages?.flatMap(
                      (page) => page.waypoints,
                    ).length
                  : 0
              }
              next={getJoinedWaypointsQuery.fetchNextPage}
              hasMore={getJoinedWaypointsQuery.hasNextPage || false} // Replace with a condition based on your data source
              loader={null}
            >
              <div className="grid md:grid-cols-2 gap-4 mb-5">
                {getJoinedWaypointsQuery?.data?.pages.map((group, i) => (
                  <React.Fragment key={i}>
                    {group.waypoints.map((waypoint, idx) => (
                      <div
                        key={idx}
                        className="flex md:items-center space-x-4 p-4 shadow-md dark:shadow-slate-950 rounded-lg"
                      >
                        <Link
                          to={`/waypoint/${waypoint.id}/${encodeURIComponent(waypoint.name)}`}
                          className="sm:w-20 w-14 sm:h-20 h-14 flex-shrink-0 rounded-lg relative cursor-pointer"
                        >
                          <img
                            src={
                              'data:image/svg+xml;utf8,' +
                              encodeURIComponent(
                                minidenticon(waypoint ? waypoint.name : ''),
                              )
                            }
                            className="object-cover w-full h-full rounded-full ring-1 ring-neutral-200 dark:ring-neutral-400 p-1"
                            alt={`${waypoint.name} Avatar`}
                          />
                        </Link>
                        <Link
                          to={`/waypoint/${waypoint.id}/${encodeURIComponent(waypoint.name)}`}
                          className="flex-1 cursor-pointer"
                        >
                          <div className="md:text-lg text-base font-semibold capitalize text-black dark:text-white">
                            {waypoint.name}
                          </div>

                          <div className="text-sm text-gray-500 line-clamp-2">
                            {waypoint?.description}
                          </div>
                        </Link>
                        <button
                          type="button"
                          className="button border dark:border-gray-600/60 dark:text-slate-50 text-slate-800 my-auto"
                          data-uk-toggle="target: #waypoint-details"
                          onClick={() => setWaypoint(waypoint)}
                        >
                          <i className="la la-minus-circle md:text-lg"></i>
                        </button>
                      </div>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            </InfiniteScroll>
          </>
        )}

        <div id="waypoint-details-modal-wrapper">
          {leaveWaypointMutation.isLoading && <Loader />}

          <div
            className="hidden lg:p-20 uk- open"
            id="waypoint-details"
            data-uk-modal="container: #waypoint-details-modal-wrapper;"
          >
            <div className="uk-modal-dialog tt relative overflow-hidden mx-auto bg-white shadow-xl rounded-lg md:w-[520px] w-full dark:bg-dark2 text-black dark:text-white">
              <div className="text-center py-4 border-b mb-0 dark:border-slate-700">
                <div className="text-base font-semibold text-black dark:text-white px-10">
                  {waypoint?.name}
                </div>
                <button
                  type="button"
                  className="button-icon absolute top-0 right-0 m-2.5 uk-modal-close"
                  onClick={() => setWaypoint(undefined)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div className="p-5 text-sm">
                <div className="mb-4 last:mb-0">
                  <div className="text-sm text-center">
                    {waypoint?.description}
                  </div>

                  <button
                    type="button"
                    className="mt-4 button bg-[#db00ff] dark:text-slate-50 text-slate-800 w-full text-base uk-modal-close"
                    onClick={() => {
                      if (waypoint) {
                        void leaveWaypointMutation.mutate(waypoint.id);
                      }
                    }}
                  >
                    Leave Waypoint
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default JoinedWaypoints;
